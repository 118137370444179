import React from "react";
import { useProducts } from "../hooks/useProducts";
import { Searchbar } from "./Searchbar";
import { Home } from "../pages/Home";
import { Header } from "./Header";
function CategoryList({APIList}){

    const {
        states, stateUpdates
      } = useProducts(APIList);
  

      const {
        searchValue ,
        searchedProducts ,
      } = states;

    	  
		  const {
        setSearchValue ,
        } = stateUpdates;

    return(
        <>
        <Header searchValue={searchValue} setSearchValue={setSearchValue}/>
            <Searchbar 
                className="navbar-search_mobile"  
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
            />
            <Home searchedProducts={searchedProducts}/>
        </>
    );
}

export { CategoryList };