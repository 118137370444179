import React, { useEffect, useState} from "react";
import { ProductInfo } from "../components/ProductInfo";
import '../styles/ProductDetail.scss';
import { useParams, useNavigate } from "react-router";
import axios from "axios";
import { useGetProducts } from "../hooks/useGetProducts";
import { Footer } from "../components/Footer";
import { useLocation } from "react-router-dom";

const ProductDetail = () => {
    // const slug = 'mvm10149';
    const { slug } = useParams();	
	const [product, setProduct] = useState({});
	const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [displayModal, setDisplayModal] = useState(false);
    const [landingMode, setLandingMode] = useState(false);
    const [landingClass, setLandingClass] = useState('');
    
    const pathname = useLocation().pathname.split('/');

    useEffect(()=>{
        async function axiosData() {
        const response = await axios(`https://miapiatmosphere.cloud/api/v100/products/${slug}`);

        setProduct(response.data);
		setLoading(false);
        };
		axiosData();
    }, [slug]);    

    useEffect(() =>{
        if(pathname.some(e => e === 'landingpage') && pathname.some(e => e === 'mvm10149')){
            setLandingMode(true);
            setLandingClass('landingModeClass');
        }});
        const categories = useGetProducts('https://miapiatmosphere.cloud/api/v100/products/get-categories');


    

    return loading ? (<p>Loading...</p>) : (
        <>
		<section className={`ProductDetail ${landingClass}`}>
            <div className={`ModalBackground-${displayModal}`} id="product-detail__modal">
                <div>
                    <p>Antes de continuar con tu compra debes elegir todas las características de tu producto.</p>
                    <button className="primary-button" onClick={() => setDisplayModal(false)}>Aceptar</button>
                </div>
            </div>
            {/* {console.log('category:::',categories.find(e => e.idCategory === product.catalogue.idCategory).name)}
            {console.log('product: ', product)}
            {console.log('categories', categories)} */}
            {!landingMode && <div className="route-container">
                <span className="dynamic-span" onClick={() => navigate(`/catalog/${categories.find(e => e.idCategory === product.catalogue.idCategory).name.toLowerCase()}`)}>{categories.length > 0 && product ? (categories.find(e => e.idCategory === product.catalogue.idCategory).name) : '.....'}</span>
                <span> &gt; </span><span className="dynamic-span" onClick={() => navigate(`/catalog/${categories.find(e => e.idCategory === product.catalogue.idCategory).name.toLowerCase()}/${product.catalogue.description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, '-')}`, 
                {state:{id: product.idCatalogue, description: product.catalogue.description, category: product.catalogue.idCategory}}
                )}>{product && product.catalogue.description}</span>
                <span> &gt; </span><span>{product && product.name}</span>
            </div>}
            <ProductInfo 
                product={product} setDisplayModal={setDisplayModal}
                />
		</section>
        </>);
}

export { ProductDetail };