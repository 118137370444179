import { useParams } from "react-router";
import { CategoryList } from "./CategoryList";

const SeasonList = () => {    
    
    const { slug } = useParams();
    // let { state } = useLocation();
    // console.warn("STATE",state)
    const seasonalPoint = `https://miapiatmosphere.cloud/api/v100/products/get-products?season=${slug}`;
    return(
    <>
        <div className="IntroContainer">
        <h2>Productos de Temporada</h2>
        </div>
        <CategoryList APIList={seasonalPoint}></CategoryList>
    </>
    );
}

export {SeasonList}