import React from "react";
import { CategoryList } from "./CategoryList";

const promotionsAPI = "https://miapiatmosphere.cloud/api/v100/products/get-promotions";

const PromotionalProducts = () => {
    return(
        <>
            <div className="IntroContainer">
            <h2>Productos en descuento</h2>
            </div>
            <CategoryList APIList={promotionsAPI} />
        </>
    );
}

export { PromotionalProducts };