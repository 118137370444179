import React, { useEffect } from "react";
import { Layout } from "../containers/Layout";
import { Home } from "../pages/Home";
import { useProducts } from "../hooks/useProducts";
import { Header } from "../components/Header";
import { AppContext } from "../context/AppContext";
import { useInitialState } from "../hooks/useInitialState";
import { HashRouter, Routes, Route, useLocation, BrowserRouter } from "react-router-dom";
import { MyOrder } from "../containers/MyOrder";
import { Checkout } from "../containers/Checkout";
import { Cart } from "../components/Cart";
import { Email } from "../components/Email";
import { Terms } from "../components/Terms";
import { DataPolicy } from "../components/DataPolicy";
import { Delivery } from "../components/Delivery";
import { Payment } from "../components/Payment";
import {
  PaymentAccepted,
  PaymentRejected,
  PaymentPending,
  LandingDeliveryConfirmed
} from "../components/Payment-state";
import ScrollToTop from "../hooks/useScrollToTop";
import { Intro } from "../components/Intro";
import { Searchbar } from "../components/Searchbar";
import { ProductDetail } from "../containers/ProducDetail";
import { Catalog } from "../containers/Catalog";
import { CatalogList } from "../components/CatalogList";
import { SeasonList } from "../components/SeasonList";
import { Footer } from "../components/Footer";
import { CatalogColors } from "../components/CatalogColors";
import { PromotionalProducts } from "../components/PromotionalProducts";
import logo from '../assets/logos/ILoveMyStyle-onlyLogo.gif';
import { DeliveryLanding } from "../components/DeliveryLanding";

const API = "https://miapiatmosphere.cloud/api/v100/products/get-products";

function App() {
  const initialState = useInitialState();

  const { states, stateUpdates } = useProducts(API);

  const { searchValue, searchedProducts } = states;

  const { setSearchValue } = stateUpdates;

  return (
    <AppContext.Provider value={initialState}>
      <React.Fragment>
        <BrowserRouter>
          <Layout searchValue={searchValue} setSearchValue={setSearchValue} />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header searchValue={searchValue} setSearchValue={setSearchValue}/>
                  <Intro searchValue={searchValue}></Intro>
                  <Searchbar
                    className="navbar-search_mobile"
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                  />
                  <Home searchedProducts={searchedProducts} />
                  <Footer />
                </>
              }
            />
            <Route path="/product/:slug" element={
              <>
                <Header searchValue={searchValue} setSearchValue={setSearchValue}/>
                <ProductDetail />
                <Footer />
              </>
            } />
            <Route path="/landingpage/:slug" element={
              <>
                  <img src={logo} alt="logo" style={{width: "200px", position: "relative",
                left: "calc(50% - 100px)", top: "20px"}} />
                <ProductDetail />
                <Footer />
              </>
            } />
            <Route path="/landingpage/delivery-and-payment" element={
              <>
                <img src={logo} alt="logo" style={{width: "200px", position: "relative",
                left: "calc(50% - 100px)", top: "20px"}} />
                <DeliveryLanding />
                <Footer />
              </>
            }/>
            <Route path="/landingpage/confirmation" element={
              <>
                <img src={logo} alt="logo" style={{width: "200px", position: "relative",
                left: "calc(50% - 100px)", top: "20px", marginBottom: "20px"}} />
                
                <LandingDeliveryConfirmed />                
                <Footer />
              </>
            }/>
            <Route
              path="/catalog/:slug"
              element={
                <>
                  <Catalog></Catalog>
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/catalog/:slug/:slug"
              element={
                <>
                  <CatalogList></CatalogList>
                  <Footer />
                </>
              }
            />
            <Route path="/season/:slug" element={
              <>
              <SeasonList />
              <Footer />
              </>
            } />
            <Route path="/promotional-products" element={
              <>
                <PromotionalProducts />
                <Footer />
              </>
            }
            ></Route>
            <Route path="/patterns" element={
            <>
              <Header searchValue={searchValue} setSearchValue={setSearchValue}/>
              <CatalogColors />
            </>}></Route>
            <Route path="/checkout" element={
            <>
              <Header searchValue={searchValue} setSearchValue={setSearchValue}/>
              <Checkout />
            </>}>
              <Route path="cart" element={<Cart />} />
              <Route path="email" element={<Email />} />
              <Route path="delivery-and-payment" element={<Delivery />} />
              <Route path="payment-accepted" element={<PaymentAccepted />} />
              <Route path="payment-rejected" element={<PaymentRejected />} />
              <Route path="payment-pending" element={<PaymentPending />} />
            </Route>
            <Route path="/terminos-y-condiciones" element={
              <>
              <Header searchValue={searchValue} setSearchValue={setSearchValue}/>
              <Terms />
              <Footer />
              </>}></Route>
            <Route path="/data-policy" element={
              <>
              <Header searchValue={searchValue} setSearchValue={setSearchValue}/>
              <DataPolicy />
              <Footer />
              </>}></Route>
            <Route
              path="*"
              element={
                <>
                  <Header searchValue={searchValue} setSearchValue={setSearchValue}/>
                  <p>
                    Pellentesque habitant morbi tristique senectus et netus et
                    malesuada fames ac turpis egestas. Vestibulum tortor quam,
                    feugiat vitae, ultricies eget, tempor sit amet, ante. Donec
                    eu libero sit amet quam egestas semper. Aenean ultricies mi
                    vitae est. Mauris placerat eleifend leo. Quisque sit amet
                    est et sapien ullamcorper pharetra. Vestibulum erat wisi,
                    condimentum sed, commodo vitae, ornare sit amet, wisi.
                    Aenean fermentum, elit eget tincidunt condimentum, eros
                    ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec
                    non enim in turpis pulvinar facilisis. Ut felis. Praesent
                    dapibus, neque id cursus faucibus, tortor neque egestas
                    augue, eu vulputate magna eros eu erat. Aliquam erat
                    volutpat. Nam dui mi, tincidunt quis, accumsan porttitor,
                    facilisis luctus, metus
                  </p>
                  <p> No encontrado. Debe personalizarse....</p>
                </>
              }
            />
          </Routes>
        </BrowserRouter>
      </React.Fragment>
    </AppContext.Provider>
  );
}

export default App;
