import React, { useState, useContext, useEffect} from "react";
import { AppContext } from "../context/AppContext";
import { OrderItem } from "../components/OrderItems";
import '../styles/MyOrder.scss';
import flechita from '../assets/icons/flechita.svg';
import { Link, useNavigate } from "react-router-dom";

const MyOrder = ({setToggleOrders}) => {

	const navigate = useNavigate();

	const { state } = useContext(AppContext);	

	const sumTotal = () => {
		const reducer = (accumulator, currentValue) => (accumulator + (currentValue.quantity*(currentValue.features.find(e => e.idFeature === currentValue.idFeature).price - currentValue.features.find(e => e.idFeature === currentValue.idFeature).price * currentValue.features.find(e => e.idFeature === currentValue.idFeature).discount)));
		const sum = state.cart.reduce(reducer, 0);
		return sum.toFixed();
	}

    return(
		<aside className="MyOrder">
			<div className="title-container">
				<img src={flechita} className="my-order-arrowback" alt="arrow" onClick={()=> setToggleOrders(false)} />
				<p className="title">Mi orden</p>
			</div>
			<div className="my-order-content">
				{state.cart.map(product => {
				     return(
					 <OrderItem product={product} key={`orderItem-${product.idAggregated}`} />
					 )
				})}
			</div>
			<div className="order">
					<p>
						<span>Total</span>
					</p>
					<p>$
						{`${sumTotal().toString().length > 6 ? ((sumTotal().toString().slice(- sumTotal().toString().length, -6)+'.')) : ''}`}
						{`${sumTotal() !== 0 ? ((sumTotal().toString().slice(-6,-3))+'.'+(sumTotal().toString().slice(-3))): sumTotal()}`}
					</p>
				</div>
				<button className="primary-button" onClick={()=> {
					navigate('/checkout/cart');
					setToggleOrders(false)}}>
					Continuar compra
					{/* <Link to="/checkout/cart">Continuar compra</Link> */}
				</button>
		</aside>);
}

export { MyOrder };