import React, { useState, useContext, useEffect} from "react";
import { AppContext } from "../context/AppContext";
import { OrderItem } from "../components/OrderItems";
import '../styles/Cart.scss';
import flechita from '../assets/icons/flechita.svg';
import { Link, Outlet, useNavigate } from "react-router-dom";
import { ScrollToTop} from "../hooks/useScrollToTop";
import { postProducts } from "../hooks/postProducts";

const sendAPI = 'https://miapiatmosphere.cloud/api/v100/shopping-car/step-one';

const Cart = () => {
	const { state } = useContext(AppContext);
    const [isDisabled, setIsDisabled] = React.useState(false);
	const navigate = useNavigate();
	ScrollToTop();	

	const sumTotal = () => {
		const reducer = (accumulator, currentValue) => (accumulator + (currentValue.quantity*(currentValue.features.find(e => e.idFeature === currentValue.idFeature).price - currentValue.features.find(e => e.idFeature === currentValue.idFeature).price * currentValue.features.find(e => e.idFeature === currentValue.idFeature).discount)));
		const sum = state.cart.reduce(reducer, 0);
		return sum.toFixed();
	}

	async function handleStart() {
		// setTimeout(() => {
		// 	postProducts(postURL, values);
		//   }, 400);
		setIsDisabled(true);
		const selectedCart = [];
		state.cart.map(p => {
			selectedCart.push({
				idProduct: p.idProduct,
				idFeature: p.idFeature,
				nameProduct: p.name,
				color: p.selectedColor, 
				otherAttribute: p.selectedAttribute,
				size: p.selectedSize,
				quantity: p.quantity,
				price: p.features.find(e => e.idFeature === p.idFeature).price,
			});
		});
		state.cart.map(p => console.log('Este es el producto p:' ,p));
		console.log('Este es el cart::::', state.cart);
		console.log('Este es el selectedCart::::', selectedCart);
		
		console.log('Ahora viene el post ------>');
		const respuestaCarro = await postProducts(sendAPI, selectedCart);
		console.log('la respuesta que regresa al carro', respuestaCarro.data);
		if(respuestaCarro.status === 200 && respuestaCarro.data.state){
			let stateData = JSON.parse(JSON.stringify(respuestaCarro.data));
			navigate("/checkout/email", {state:{...stateData}});
		}
		else {
			alert('Ha ocurrido un error, vuelve a cargar la página.');
		}
	}

    return(
		<>
			<div className="final-order-content">
				{state.cart.map(product => {
				     return(
					 <OrderItem product={product} key={`orderItem-${product.idAggregated}`} />
					 )
				})}
			</div>
			<div className="order">
					<p>
						<span>Total</span>
					</p>
					<p>$
						{`${sumTotal().toString().length > 6 ? ((sumTotal().toString().slice(- sumTotal().toString().length, -6)+'.')) : ''}`}
						{`${sumTotal() !== 0 ? ((sumTotal().toString().slice(-6,-3))+'.'+(sumTotal().toString().slice(-3))): sumTotal()}`}
					</p>
				</div>
				<div className="warning-container">
					<p>El valor anterior no incluye el pago del envío.</p>
					<p>Si el pedido es enviado a su domicilio, el costo del envío debe pagarse contraentrega a la empresa de mensajería.</p>
				</div>
				<button
                    disabled={isDisabled} className="primary-button" onClick={handleStart}>
					Iniciar Pago
				</button>
		</>);
}

export { Cart };