import React from "react";
import introImage from "../assets/icons/intro.jpg";
import "../styles/Intro.scss";
import { Link, useNavigate } from "react-router-dom";
import { useGetProducts } from "../hooks/useGetProducts";

const Intro = ({searchValue}) => {
    let navigate = useNavigate(); 
    const seasonPoint = 'https://miapiatmosphere.cloud/api/v100/products/get-covers';
    const covers = useGetProducts(seasonPoint);

    const cataloguesPoint = 'https://miapiatmosphere.cloud/api/v100/products/get-intro';
    const mainCatalogues = useGetProducts(cataloguesPoint);
    // console.log("Covers: ", covers[0]);

    
    const categoriesPoint = "https://miapiatmosphere.cloud/api/v100/products/get-categories";
    const allCategories = useGetProducts(categoriesPoint);

    console.log('searchValue: ', searchValue);
    return(
        <>
        <section className="IntroContainer">
        <div className={`flip-box ${searchValue ? 'Searching' : ''}`} onClick={() => navigate('/promotional-products')}>
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <h2>Descuentos
                    <br />10, 20, 50%
                </h2>
              </div>
              <div className="flip-box-back">
                <h2>Ofertas</h2>
              </div>
            </div>
        </div>   
        {
            covers[0] ? 
            <div className={searchValue ? 'Searching' : ''}>
                <img src={covers.length > 0 ? covers[0].images: null} alt={`Portada de la temporada ${covers[0].season}`} className="introImage"/>
                <div className="container__catalog-button">
                    <div>{covers.length > 0 ? covers[0].message: 'Colección de temporada'}</div>                    
                    <button className="Catalog-button" 
                    onClick={ () => navigate(`/season/${covers[0].season.toLowerCase()}`)}
                    >
                        {covers.length > 0 ? covers[0].button : 'Ver colección'}
                    </button>
                </div>
            </div> :
            <div></div>
        }
        <div className={`Container--Intro__CategoryList ${searchValue ? 'Searching' : ''}`}>
            <h2>NUESTROS CATÁLOGOS DESTACADOS</h2>
        <div className="ProductList Intro__CategoryList">
                <div className="ProductItem Intro__category-container"
                onClick={ () => navigate(`/season/${covers[1].season.toLowerCase()}`)}>
                {covers.length > 0 && (covers[1].images &&
					<img src={covers[1].images} alt={covers[1].message}/>
					)			
				}
                    {covers.length > 0 &&
                    <span>
                        {covers[1].message.toUpperCase()}
                    </span>}
                </div>
                <div className="ProductItem Intro__category-container" onClick={() => navigate('/promotional-products')}>
                {covers.length > 0 && (covers[1].images &&
					<img src={covers[2].images} alt={covers[2].message}/>
					)			
				}
                    {covers.length > 0 &&
                    <span>
                        {covers[2].message.toUpperCase()}
                    </span>}
                </div>
                {mainCatalogues.length > 0 ? mainCatalogues.map(c => {
					// console.log('each catalog: ', c);
                    return(
                        <div className="ProductItem Intro__category-container" key={c.description}
                        onClick={() => {
                            if(allCategories.length > 0){
                                navigate(`/catalog/${allCategories.find(e => e.idCategory === c.idCategory).name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, '-')}/${c.description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, '-')}`)}}}
                        >
                            <img src={c.image} alt={c.description}/>
                            <span>
                                {c.name.toUpperCase()}
                            </span>
                        </div>
                    )			
				}) : <div className="ProductItem Intro__category-container"><span>Cargando catalogo...</span></div>}
            </div>
        </div>
            <div>
            <h2>TODOS NUESTROS PRODUCTOS</h2>
            </div>
        </section>
        </>
    )
}

export { Intro };