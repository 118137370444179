import axios from "axios";
import { useEffect, useState } from "react";

const useGetCalatog = (catalogueId) => {
  // const catalogueId = slug.
  const endpoint = `https://miapiatmosphere.cloud/api/v100/products/get-catalogues/${catalogueId}`;
  const [catalog, setCatalog] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      const response = await axios(endpoint);
      setCatalog(response.data);
      setLoading(false);
    }
    getData();
  }, [endpoint]);

  return { catalog, loading };
};

export { useGetCalatog };
