import { useEffect, useState } from "react";

const localStorageItems = localStorage.getItem('MyItems_V1');
let parsedItems;


console.log('LOCALSTARAGE------:', localStorageItems);

if(!localStorageItems){
    localStorage.setItem('MyItems_V1', JSON.stringify([]));
    parsedItems = [];
}
else {
    parsedItems = JSON.parse(localStorageItems);
}


const initialState= {
    cart: parsedItems,
};

const useInitialState = () => {
    const [state, setState] = useState(initialState);

    const checkCartExpiration = () =>{
        const lastProductAddedDate = localStorage.getItem('lastProductAddedDate');
        
        if(localStorageItems && lastProductAddedDate){
            const expirationTime = 3 * 24 * 60 * 60 * 1000; // 3 days in miliseconds
            const currentTime = new Date().getTime();
            if (currentTime - parseInt(lastProductAddedDate) > expirationTime) {
                localStorage.removeItem('MyItems_V1');
                localStorage.removeItem('lastProductAddedDate');
                clearCart();
              }
        }
    }

    useEffect(() => {
        localStorage.setItem('MyItems_V1', JSON.stringify(
        [...state.cart]
    ));
    checkCartExpiration();
    console.log('LOCALSTARAGE---2---:', localStorageItems);
});



    const addToCart = (payload) => {
        const currentTime = new Date().getTime();
        localStorage.setItem('lastProductAddedDate', currentTime.toString());
        payload.quantity = 1;
        setState({
            ...state,
            cart: [...state.cart, 
            payload]
        });
    }

    const removeFromCart = (payload) => {
        payload.quantity = 0;
        setState({
            ...state,
            cart: state.cart.filter(item => ( item.idAggregated !== payload.idAggregated))
        });
    }

    const clearCart = () => {
        setState({
            ...state,
            cart: [],
        });
    }

    const increaseQuantity = (payload) => {
        const itemIndex = state.cart.findIndex(item => (item.idAggregated === payload.idAggregated));
        state.cart[itemIndex].quantity = state.cart[itemIndex].quantity + 1;
        setState({
            ...state,
            cart: [...state.cart]
        });
    }
    
	const decreaseQuantity = (payload) => {
        const itemIndex = state.cart.findIndex(item => (item.idAggregated === payload.idAggregated));
		if(state.cart[itemIndex].quantity > 1){
		    state.cart[itemIndex].quantity = state.cart[itemIndex].quantity - 1;
            setState({
                ...state,
                cart: [...state.cart]
            });
		}
		else if(state.cart[itemIndex].quantity <= 1) {
			removeFromCart(payload);
		}    
	}

        return {
            state, 
            addToCart,
            removeFromCart,
            clearCart,
            increaseQuantity,
            decreaseQuantity,
        }
}

export { useInitialState };