import React, { useEffect, useState, useContext } from 'react';
import classNames from 'classnames';
import '../styles/ProductInfo.scss';
import '../styles/Email.scss'
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { ScrollToTop } from '../hooks/useScrollToTop';
import { AppContext } from '../context/AppContext';

const ProductInfo = ({product, setDisplayModal}) => {

	const { state, addToCart, removeFromCart } = useContext( AppContext);

	const [dynamicProduct, setDynamicProduct] = useState({});

	const [source, setSource] = useState(product.images[0]);

	const [selectedAttribute, setSelectedAttribute] = useState('');
	const [selectedSize, setSelectedSize] = useState('');
	const [selectedColor, setSelectedColor] = useState('');

    const navigate = useNavigate();

	const [selectedFeature, setSelectedFeature] = useState([]);

	const [displayVideo, setDisplayVideo] = useState(false);
	const [discountPrice, setDiscountPrice] = useState('');
	
    const [landingMode, setLandingMode] = useState(false);
    
    const pathname = useLocation().pathname.split('/');
	// let location = useLocation();
	
	ScrollToTop();

	
    useEffect(() =>{
        if(pathname.some(e => e === 'landingpage') && pathname.some(e => e === 'mvm10149')){
            setLandingMode(true);
        }});

	useEffect(()=> {
		// if(product.features.length === 1 && (product.features[0].discount !== 0)){
		// 	setDiscountPrice('Discount-price');
		// }
		if((product.features.some(feature => feature.discount !==0)) && (selectedFeature.length === 0) && (product.catalogue.idCategory !=="F001")){
			setDiscountPrice('Discount-price');
		}
		else if(product.features.length > 0){
			if(selectedFeature.length > 0 && ((product.features.find(obj => (obj.idFeature === selectedFeature.flat()[0])).discount !== 0))){
				setDiscountPrice('Discount-price');
			}
			else {
				setDiscountPrice('');
			}
		}
	}, [selectedFeature]);
	// useEffect(() => setSource(product.images[0]), [product]);
	useEffect(() => {
		if(product.images.length > 0){
		setSource(product.images[0]);
		setDisplayVideo(false);}
		else {
			setSource(product.videos[0]);
			setDisplayVideo(true);
	 }}, [product]);

	useEffect(() => {
		let productObject = {...product}
		productObject.idFeature = selectedFeature.toString();
		productObject.idAggregated = `${selectedFeature.toString()}-${selectedAttribute.toString() !== '' ? selectedAttribute.toString().replace(/\s/g, '') : 'noAttribute' }-${selectedSize.toString() !== '' ? selectedSize.toString().replace(/\s/g, ''): 'noSize'}-${selectedColor.toString() !== '' ? selectedColor.toString().replace(/\s/g, ''): 'noColor'}`;
		productObject.selectedAttribute = selectedAttribute.toString();
		productObject.selectedSize = selectedSize.toString();
		productObject.selectedColor = selectedColor.toString();
		setDynamicProduct(productObject);
	},[selectedAttribute, selectedColor, selectedSize]);
	
	const addProduct = item => {
		console.log('dynamicProduct', dynamicProduct);
		console.log('addProduct state.cart: ', state.cart)
		console.log('addProduct item: ', item)
		// if(state.cart.includes(item)){
		// 	removeFromCart(item);
		// }
		if(state.cart.some(e => (e.idAggregated === item.idAggregated))){
			removeFromCart(item);
		}
		else {
			addToCart(item);
		}
	}

	const addProductLanding = item => {
		console.log('Este es el producto agregado en el landing: ', item);
		item.quantity = 1;
		navigate("/landingpage/delivery-and-payment", {state:{...item}});
	}

	const handleSource = (e) => {
		console.log("e.target.localName", e.target.localName);
		if(e.target.localName === 'img'){
			setSource(e.target.src);
		}
		else if(e.target.localName === 'video'){
			setSource(e.target.currentSrc);
		}
	}


	const handleCart = () => {
		console.log('En el handleCart; feature, size, color:',[selectedAttribute, selectedSize, selectedColor]);
		let uniqueSelectedFeature = selectedFeature[0][0];
		console.log('selectedFeature que se se agrega: ', selectedFeature);
		console.log('uniqueSelectedFeature que se se agrega: ', uniqueSelectedFeature);
		// const newProduct = {...product};
		const newProduct = JSON.parse(JSON.stringify(product));
		newProduct.idFeature = uniqueSelectedFeature.toString();
		newProduct.idAggregated = `${selectedFeature.toString()}-${selectedAttribute.toString() !== '' ? selectedAttribute.toString().replace(/\s/g, '') : 'noAttribute' }-${selectedSize.toString() !== '' ? selectedSize.toString().replace(/\s/g, ''): 'noSize'}-${selectedColor.toString() !== '' ? selectedColor.toString().replace(/\s/g, ''): 'noColor'}`;
		newProduct.selectedAttribute = selectedAttribute.toString();
		newProduct.selectedSize = selectedSize.toString();
		newProduct.selectedColor = selectedColor.toString();
		console.log('Este es el producto que se pasa en el handleCart: ', newProduct);
		if(landingMode){
			addProductLanding(newProduct);
		} else{
			addProduct(newProduct);
		}
		// if(state.cart.some(item => (product.idProduct === item.id))){
		// 	setEnable(true);
		// } else {
		// 	setEnable(false);
		// }
	}

  
	  const resultColors = {};
	  const resultSizes = {};
	  const resultOtherAttributes = {};
	  

	  for (let i = 0; i < product.features.length; i++) {
		let feature = product.features[i];
	  
		if(feature.colors){
		for (let j = 0; j < feature.colors.length; j++) {
		  const color = feature.colors[j];
	  
		  if (!resultColors[color]) {
			resultColors[color] = [];
		  }
	  
		  resultColors[color].push(feature.idFeature);
		}}

		if(feature.sizes){
			for (let j = 0; j < feature.sizes.length; j++) {
			  const size = feature.sizes[j];
		  
			  if (!resultSizes[size]) {
				resultSizes[size] = [];
			  }
		  
			  resultSizes[size].push(feature.idFeature);
			}}

			if(feature.otherAttributes){
				for (let j = 0; j < feature.otherAttributes.length; j++) {
				  const anotherAttribute = feature.otherAttributes[j];
			  
				  if (!resultOtherAttributes[anotherAttribute]) {
					resultOtherAttributes[anotherAttribute] = [];
				  }
			  
				  resultOtherAttributes[anotherAttribute].push(feature.idFeature);
				}}
	  }

	  
	  const finalResultColor = Object.entries(resultColors).map(([color, idFeatures]) => ({ [color]: idFeatures }));
	  const finalResultSize = Object.entries(resultSizes).map(([size, idFeatures]) => ({ [size]: idFeatures }));
	  const finalResultAnotherAttribute = Object.entries(resultOtherAttributes).map(([anotherAttribute, idFeatures]) => ({ [anotherAttribute]: idFeatures }));
	  
	  const handleAnotherAttribute = (e) => {
		setSelectedAttribute(Object.keys(e).flat());
		setSelectedSize('');
		setSelectedColor('');
		console.log('selected attribute:', selectedAttribute);
	  }

	  const handleSize = (e) => {
		console.log('selected feature' ,selectedFeature.flat());
		console.log('object values: ', Object.values(e).flat());
		if(product.features.some(p => p.otherAttributes !== null)){
			if(selectedFeature.flat().filter(value => Object.values(e).flat().includes(value)).length > 0){
				setSelectedSize(Object.keys(e).flat());
				setSelectedColor('');
			} else {
				console.log('This size is not available.');
			}
		} else {
			setSelectedSize(Object.keys(e).flat());
			setSelectedColor('');
		}
		console.log('selected size: ', selectedSize);
	  }

	  const handleColor = (e) => {
		if(selectedFeature.flat().filter(value => Object.values(e).flat().includes(value)).length > 0){
			setSelectedColor(Object.keys(e).flat());
		} else {
			console.log('This color is not available.');
		}
		console.log('selected color: ', selectedColor);
	  }

	  const handleSelection = (e) => {
		let newSelected = [...Object.values(e)]
		if(selectedFeature.length === 0)
		{
			setSelectedFeature(newSelected)}
		else{
			if(selectedFeature.filter(value => Object.values(e).includes(value)).length > 0){
				let newSelectedArray = selectedFeature.filter(value => Object.values(e).includes(value));
				setSelectedFeature(newSelectedArray);
			}
			else { 
				setSelectedFeature(newSelected);
		  }
		}
		}
	  

		const CompareArrays = (array3, array4) => {
			const array1 = [...array3];
			const array2 = [...array4];
			let filteredArray = array1.flat().filter(value => array2.flat().includes(value));
			return filteredArray;
		}

	const handlePurchase = () => {
		if(selectedFeature.toString()){
			console.log('selectedFeature.toString()', selectedFeature.toString())
			if(product.features.some(p => p.sizes !== null) && selectedSize.toString() !== ''){
				if(product.features.some(p => p.colors !== null) && selectedColor.toString() !== ''){
					handleCart();
				} else if(!product.features.some(p => p.colors !== null)){
					handleCart();
				} else {
					setDisplayModal(true);
				}
			}
			else if (product.features.some(p => p.sizes !== null)){
				setDisplayModal(true);
			}
			else if (!product.features.some(p => p.sizes !== null)) {
				if(product.features.some(p => p.colors !== null) && selectedColor.toString() !== '') {
					handleCart();
				}
				else if(!product.features.some(p => p.colors !== null)){
					handleCart();
				}
			}
		}
		else {
			setDisplayModal(true);
		}
		}	

		const pfzero = product.features[0].price;
		
		const pfdiscount = product.features.find(feature => feature.discount !==0);
	return (
		<>
			<div className='container-all-images'>
				<div className='container-bigImages'>
					{product.features.some(feature => feature.discount !== 0) && <div className='Discount-image'>DESCUENTO {(selectedFeature.length === 0) ? (product.features.find(feature => feature.discount !== 0).discount * 100).toFixed() : ((product.features.find(obj => (obj.idFeature === selectedFeature.flat()[0]))).discount * 100).toFixed()}%</div>}
					{!displayVideo ?
						<img src={source} key={`main-${product.name}`} alt={product.name} /> :
						<video 
						controls
						key={`main-${product.name}-video`}
						>
						  <source src={source} type="video/mp4" />
						  <source src={source} type="video/webm" />
						</video>
					}
					
				</div>
				<div className='container-smallImages'>
					{product.images.length > 0 &&
						product.images.map(image => 
							<img src={image} 
								 key={`${product.name}-imagen-${product.images.indexOf(image)}`}
								 className={`${source === image && 'Image-clicked'}`}
								 alt={`${product.name}-${product.images.indexOf(image)}`}
								 onClick={e => {
									console.log(e);
									setDisplayVideo(false);
									handleSource(e)}} />)}
					{product.videos.length > 0 &&
						product.videos.map(vd =>
							// <img src={vd}></img>
							// <iframe width="420" height="315"
							// src={product.videos[0]}>
							// </iframe>
								<video 
								// controls
								key={`${product.name}-video-${product.videos.indexOf(vd)}`}
								className={`${source === vd && 'Image-clicked'}`
								}
								onClick={e => {
									console.log(e);
									setDisplayVideo(true);
									handleSource(e)
								}}
								>
								  <source src={vd} type="video/mp4" />
								  <source src={vd} type="video/webm" />
								  {console.log("video", vd)}
								</video>
							)
					}			 
				</div>
			</div>
			<h2 className='Product-title'>{product.name}</h2>
			{console.log('product: ', product)}
            <div  className='Product-features__container'>
				{ product.features.some(p => p.otherAttributes !== null) && (
					<div>
                    <p>Tipo de Producto:</p>
                    <ul className='Product-features'>					
						{Object.values(finalResultAnotherAttribute).map(e => (
							<li 
								className={`${Object.keys(e).flat()[0] === selectedAttribute[0] ? 'Feature-selected' : 'Feature-not-selected'}`}
								key={`anotherAttribute-${Object.keys(e)}`
							} 
								onClick={() => {
								handleSelection(e);
								handleAnotherAttribute(e);
							}}
							>
								{Object.keys(e)}
							</li>))}
                    </ul>
                    </div>
				)}  
				{ product.features.some(p => p.sizes !== null) && (
					<div>
                    <p>Talla:</p>
                    <ul className='Product-features'>
					{Object.values(finalResultSize).map(e => (
							<li 
								className={classNames(((CompareArrays(selectedFeature, Object.values(e))).length > 0 || selectedFeature.length === 0) ? ('feature-available') : ('feature-not-available'), Object.keys(e).flat()[0] === selectedSize[0] ? 'Feature-selected' : 'Feature-not-selected')}
								
								key={`size-${Object.keys(e)}`} 
								onClick={() => {
									if(!product.features.some(p => p.otherAttributes !== null)){
									handleSelection(e)};
									handleSize(e);
								}}
							>
								{Object.keys(e)}
							</li>))}
                    </ul>
                    </div>
				)}
				{ product.features.some(p => p.colors !== null) && (
					<div>
                    <p>Color / estampado:</p>
                    <ul className='Product-features'>
					{Object.values(finalResultColor).map(e => (
							<li 
								className={classNames((CompareArrays(selectedFeature, Object.values(e)).length > 0 || selectedFeature.length === 0) ? ('feature-available') : ('feature-not-available'), Object.keys(e).flat()[0] === selectedColor[0] ? 'Feature-selected' : 'Feature-not-selected')}
								key={`color-${Object.keys(e)}`}
								onClick={() => {handleColor(e)}}
							>
								{Object.keys(e)}
							</li>))}
                    </ul>
					{finalResultColor.map(e => Object.keys(e)).flat().some(i => i.toLowerCase().replace(' ', '') === 'otroestampado') && 
						<div className='Container-Patterns__link'>
							<a href="/#/patterns" target='_blank' className='Patterns__link'>Ver estampados disponibles</a>
						</div>}
					{/* {console.log('true or false: ',finalResultColor.map(e => Object.keys(e)).flat().some(i => i === 'Personalizado'))}
					{console.log('finalResultColor', finalResultColor)}
					{console.log('Object.values(finalResultColor):', Object.values(finalResultColor))}
					{console.log('Object.values(finalResultColor).keys:', Object.values(Object.values(finalResultColor)))} */}
                    </div>
				)}                  
            </div>
			<div className="ProductInfo">
				<div className='container-product-purchase'>
					<div className='container-product-purchase__price'>
						<div>
							<p>Precio unidad</p>
							{/* <p className={discountPrice}>${(selectedFeature.length === 0) ? (product.features[0].price) : (product.features.find(obj => (obj.idFeature === selectedFeature.flat()[0]))).price}
							</p> */}

							<p className={discountPrice}>${(selectedFeature.length === 0) ? (`${pfzero.toString().slice(-6, -3)}.${pfzero.toString().slice(-3)}`) : (`${(product.features.find(obj => (obj.idFeature === selectedFeature.flat()[0]))).price.toString().slice(-6, -3)}.${(product.features.find(obj => (obj.idFeature === selectedFeature.flat()[0]))).price.toString().slice(-3)}`)}
							</p>
						</div>
						{
							((product.features.some(feature => feature.discount !==0)) && (selectedFeature.length === 0) && (product.catalogue.idCategory !=="F001")) ? (
								<div>
									<p>con descuento</p>
									{/* <p>${product.features[0].price - product.features[0].price * product.features[0].discount}</p> */}

									<p>${`${(pfdiscount.price - (pfdiscount.price * pfdiscount.discount.toFixed(2))).toFixed().toString().slice(-6,-3)}.${(pfdiscount.price - (pfdiscount.price * pfdiscount.discount.toFixed(2))).toFixed().toString().slice(-3)}`}</p>
								</div>
							)
							: 
							((selectedFeature.length > 0) && (product.features.find(obj => (obj.idFeature === selectedFeature.flat()[0])).discount !== 0)) && (
								<div>
									<p>con descuento </p>
									<p>${`${(product.features.find(obj => (obj.idFeature === selectedFeature.flat()[0])).price - product.features.find(obj => (obj.idFeature === selectedFeature.flat()[0])).price * product.features.find(obj => (obj.idFeature === selectedFeature.flat()[0])).discount.toFixed(2)).toFixed().toString().slice(-6, -3)}.${(product.features.find(obj => (obj.idFeature === selectedFeature.flat()[0])).price - product.features.find(obj => (obj.idFeature === selectedFeature.flat()[0])).price * product.features.find(obj => (obj.idFeature === selectedFeature.flat()[0])).discount.toFixed(2)).toFixed().toString().slice(-3)}`}</p>
								</div>
							)
						}
						{/* {
							((selectedFeature.length > 0) && (product.features.find(obj => (obj.idFeature === selectedFeature.flat()[0])).discount !== 0)) && (
								<div>
									<p>Precio con descuento:</p>
									<p>${product.features.find(obj => (obj.idFeature === selectedFeature.flat()[0])).price - product.features.find(obj => (obj.idFeature === selectedFeature.flat()[0])).price * product.features.find(obj => (obj.idFeature === selectedFeature.flat()[0])).discount}</p>
								</div>
							)
						} */}
					</div>
					{
						// state.cart.some(item => (product.idProduct === item.idProduct)) && 
						// state.cart.some(item => (item.idProduct === dynamicProduct.idProduct && item.idFeature === dynamicProduct.idFeature)) && 
						(!landingMode && state.cart.some(item => (item.idAggregated === dynamicProduct.idAggregated))) && 
						
						<button 
							className="primary-button add-to-cart-button"
							style={{background: "var(--very-light-pink)"}}
							onClick={handleCart}>
						Quitar del carrito
						</button>
					}{
						// !state.cart.some(item => (product.idProduct === item.idProduct)) && 
						// !state.cart.some(item => (item.idProduct === dynamicProduct.idProduct && item.idFeature === dynamicProduct.idFeature)) && 
						(!landingMode && !state.cart.some(item => (item.idAggregated === dynamicProduct.idAggregated))) && 
						<button 
							className="primary-button add-to-cart-button"
							style={{background: "var(--hospital-green)"}}
							onClick={handlePurchase}>
						Agregar al carrito
						</button>
					}{landingMode &&<button 
						className="primary-button add-to-cart-button"
						style={{background: "var(--hospital-green)"}}
						onClick={handlePurchase}
						>
					Pagar contra Entrega
					</button>
					}
                    {/* <button className="primary-button"
						onClick={()  => navigate(-1)} >
						Volver al catálogo
					</button> */}
					{/* {goPurchase  && <Navigate to="/payment-one-product" state={{ from: location }} replace />} */}
				{ (!landingMode && product.relatedProducts !== null) && (
					<div className='Related-products__container Related-products__container--laptop'>
                    <h4>Productos relacionados:</h4>
                    <ul className='Product-features'>
						{product.relatedProducts.map(
							e => (
								<li key={`related-product-${e.idProduct}`} onClick={()=> {
									setSelectedFeature([]);
									setSelectedAttribute('');
									setSelectedSize('');
									setSelectedColor('');
									navigate(`/product/${e.idProduct}`)}}>
									{e.name}
								</li>
							)
						)}						
                    </ul>
                    </div>
				)}
				</div>
			</div>
			{product.description &&
			<div className='Product-description-container'>
                <h3>Descripción</h3>
				<p className='main-text'>{product.description}</p>
			</div>
			}
			{ (!landingMode && product.relatedProducts !== null) && (
					<div className='Related-products__container Related-products__container--mobile'>
                    <h4>Productos relacionados:</h4>
                    <ul className='Product-features'>
						{product.relatedProducts.map(
							e => (
								<li key={`related-product-${e.idProduct}`} onClick={()=> {
									setSelectedFeature([]);
									setSelectedAttribute('');
									setSelectedSize('');
									setSelectedColor('');
									navigate(`/product/${e.idProduct}`)}}>
									{e.name}
								</li>
							)
						)}						
                    </ul>
                    </div>
				)}
		</>
	);
}

export { ProductInfo };