import React, { createElement } from "react";
import { ReactDOM } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, useField } from "formik";
import * as Yup from 'yup';
import { MyTextInput, MyCheckbox } from "./MyInputs";
import '../styles/Email.scss';
import { ScrollToTop } from "../hooks/useScrollToTop";

// const MyTextInput = ({ label, ...props }) => {
//     // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
//     // which we can spread on <input>. We can use field meta to show an error
//     // message if the field is invalid and it has been touched (i.e. visited)
//     const [field, meta] = useField(props);
//     return (
//       <div className="input-container">
//         <label htmlFor={props.id || props.name}>{label}</label>
//         <input className="text-input" {...field} {...props} />
//         {meta.touched && meta.error ? (
//           <div className="error text-input__error">{meta.error}</div>
//         ) : null}
//       </div>
//     );
//   };
  
  // const MyCheckbox = ({ children, ...props }) => {
  //   // React treats radios and checkbox inputs differently other input types, select, and textarea.
  //   // Formik does this too! When you specify `type` to useField(), it will
  //   // return the correct bag of props for you -- a `checked` prop will be included
  //   // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
  //   const [field, meta] = useField({ ...props, type: 'checkbox' });
  //   return (
  //     <div className="checkbox-container">
  //       <label className="checkbox-input">
  //         <input type="checkbox" {...field} {...props} />
  //         <span className="checkmark"></span>
  //         {children}
  //       </label>
  //       {meta.touched && meta.error ? (
  //         <div className="error checkbox-input__error">{meta.error}</div>
  //       ) : null}
  //     </div>
  //   );
  // };

  

function Email() {

    const [addCompany, setAddCompany] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [chargingIcon, setChargingIcon] = React.useState('NotCharging');
    
    ScrollToTop();
    let transactionInfo = JSON.parse(JSON.stringify(location.state));
    console.log('transaction info::::', transactionInfo);
    return(
        <section className="email-container">
            <h3>Identificacion</h3>
            <div>
                <Formik
                    initialValues={{
                        email: '',
                        firstName: '',
                        lastName: '',
                        idClient: '',
                        phone: '',
                        legalName: '',
                        commercialName: '',
                        nit: '',
                        rut: '',
                        acceptedTerms: false, // added for our checkbox
                        acceptedProms: false,
                      }}
                      validationSchema={Yup.object({
                        email: Yup.string()
                          .email('Direccion de correo invalida')
                          .required('Requerido'),
                        firstName: Yup.string()
                          .max(15, 'Deben ser 15 letras o menos')
                          .required('Requerido'),
                        lastName: Yup.string()
                          .max(20, 'Deben ser 20 letras o menos')
                          .required('Requerido'),
                        idClient: Yup.number()
                          .required('Requerido'),
                        phone: Yup.number()
                          .required('Requerido'),
                        acceptedTerms: Yup.boolean()
                          .required('Requerido')
                          .oneOf([true], 'Debes aceptar terminos y condiciones.'),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        setChargingIcon('Charging');
                        setTimeout(() => {
                          // alert(JSON.stringify(values, null, 2));
                          setSubmitting(false);
                          navigate("/checkout/delivery-and-payment", {state: {...transactionInfo, values}});
                        }, 400);
                      }}
                >
                    <Form>
                        <p>Solicitamos unicamente la informacion necesaria para la finalizacion de la compra.</p>
                        <MyTextInput
                          label="Correo:"
                          name="email"
                          type="text"
                          placeholder="micorreo@email.com"
                        />

                        <MyTextInput
                          label="Nombre:"
                          name="firstName"
                          type="text"
                          placeholder="Cliente"
                        />                
                        
                        <MyTextInput
                          label="Apellidos:"
                          name="lastName"
                          type="text"
                          placeholder="Apellidos"
                        />
                        
                        <MyTextInput
                          label="Cedula de ciudadania:"
                          name="idClient"
                          type="number"
                        />
                        
                        <MyTextInput
                          label="Telefono o celular:"
                          name="phone"
                          type="number"
                        />
                        
                        <p>
                            Verifica que los datos son correctos, estos seran usados para la facturacion electronica. Si deseas que la factura se emita a nombre de una empresa incresa los datos en el campo <em>Agregar datos de empresa</em>, despues de hacer el pedido no se podran realizar cambios.
                        </p>
                        <div>
                          {!addCompany &&
                            <div className="addCompany">
                            <span className="addCompany__click" onClick={() => setAddCompany(true)}>
                            <br />Agregar datos de empresa</span>
                            </div>
                            
                          }
                          {addCompany &&
                            <>
                                <div className="addCompany">
                                    <span className="addCompany__title"><br />Datos de empresa</span>
                                    <span className="addCompany__click" onClick={() => setAddCompany(false)}>No agregar</span>
                                    <br />
                                </div>
                                <MyTextInput
                                  label="Razon social:"
                                  name="legalName"
                                  type="text"
                                />
                                <MyTextInput
                                  label="Nombre de la empresa:"
                                  name="commercialName"
                                  type="text"
                                />
                                <MyTextInput
                                  label="NIT:"
                                  name="nit"
                                  type="number"
                                />
                                <MyTextInput
                                  label="RUT:"
                                  name="rut"
                                  type="number"
                                />
                            </>
                          }
                        </div>

                        <MyCheckbox name="acceptedTerms">
                            <span>He leido de manera clara y expresamente acepto y autorizo los <a href="/#/terminos-y-condiciones" target='_blank'>terminos y condiciones</a> y la <a href="/#/data-policy" target='_blank'>politica de tratamiento de datos.</a></span>                            
                        </MyCheckbox>
                        
                        <MyCheckbox name="acceptedProms">
                            <span>
                            Quiero recibir notificaciones sobre promociones y descuentos.</span>
                        </MyCheckbox>

                        <button className="primary-button" type="submit">
                          Continuar
                          {/* <Link to="/checkout/delivery-and-payment">Continuar</Link> */}
                        </button>
                    </Form>
                </Formik>
            </div>
            <div class={`lds-roller ${chargingIcon}`} ><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            
        </section>
    );
}










// function Email() {

//     const [addCompany, setAddCompany] = React.useState(false);

//     return(
//         <section className="email-container">
//             <h3>Identificacion</h3>
//             <div>
//                 <form>
//                     <p>Solicitamos unicamente la informacion necesaria para la finalizacion de la compra.</p>
//                     <label>
//                         Correo:
//                     <br />
//                         <input type='text' name='email' />
//                     </label>
//                     <br />
//                     <label>
//                         Nombre:
//                     <br />
//                         <input type='text' name='name' />
//                     </label>
//                     <br />
//                     <label>
//                         Apellidos:
//                     <br />
//                         <input type='text' name='last-name' />
//                     </label>
//                     <br />
//                     <label>
//                         Cedula de ciudadania:
//                     <br />
//                         <input type='number' name='id-number' />
//                     </label>
//                     <br />
//                     <label>
//                         Telefono o Celular:
//                     <br />
//                         <input type='number' name='phone' />
//                     </label>
//                     <br />
//                     <p>
//                         Verifica que los datos son correctos, estos seran usados para la facturacion electronica. Si deseas que la factura se emita a nombre de una empresa incresa los datos en el campo <em>Agregar datos de empresa</em>, despues de hacer el pedido no se podran realizar cambios.
//                     </p>
//                     <div>
//                         {!addCompany && 
//                             <div className="addCompany">
//                                 <span className="addCompany__click" onClick={() => setAddCompany(true)}>
//                                 <br />Agregar datos de una empresa</span>
//                             </div>}
//                         {/* {addCompany && <p onClick={() => setAddCompany(false)}>No agregar datos de la empresa.</p>} */}
//                         {addCompany && 
//                             <>
//                                 <div className="addCompany">
//                                     <span className="addCompany__title"><br />Datos de empresa</span>
//                                     <span className="addCompany__click" onClick={() => setAddCompany(false)}>No agregar</span>
//                                     <br />
//                                 </div>
//                                     <br />
//                                 <label>
//                                     Razon social:
//                                 <br />
//                                 <input type='text' name='legal-name' />
//                                 </label>
//                                 <br />
//                                 <label>
//                                     Nombre de la empresa:
//                                 <br />
//                                 <input type='text' name='commercial-name' />
//                                 </label>
//                                 <br />
//                                 <label>
//                                     NIT:
//                                 <br />
//                                 <input type='text' name='nit' />
//                                 </label>
//                                 <br />
//                                 <label>
//                                     RUT:
//                                 <br />
//                                 <input type='text' name='rut' />
//                                 </label>
//                             </>
//                         }
//                     </div>
//                     <br />
//                     <label className="label-terms">
//                         <input type='checkbox' />
//                         <span>Quiero recibir notificaciones sobre promociones y descuentos.</span>
//                     </label>
//                     <br />
//                     <label className="label-terms">
//                         <input type='checkbox' />
//                         <span>He leido y acepto la <a href="/#/terminos-y-condiciones" target='_blank'>politica de tratamiento de datos.</a></span>
//                     </label>
//                     <br />
//                 <button className="primary-button" type="submit">Continuar</button>
//                 </form>
//             </div>
//         </section>
//     );
// }

export { Email };