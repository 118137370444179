import React, { useContext, useState, useEffect} from 'react';
import { AppContext } from '../context/AppContext';
import '../styles/productItem.scss';
import { My_bt_add_to_cart } from '../assets/icons/my_bt_add_to_cart';
import '../styles/_vars.scss';
import { useNavigate } from 'react-router';

const ProductItem = ({product}) => {
	const { state, addToCart, removeFromCart } = useContext( AppContext );
    const navigate = useNavigate();
	
	const [discountPrice, setDiscountPrice] = useState('');

	useEffect(() => {
		if((product.catalogue.idCategory !== 'F001') && (product.features.some(feature => feature.discount !==0))){
			setDiscountPrice('Discount-price');
		} else {
			setDiscountPrice('');
		}
	});

	const handleClick = item => {
		if(state.cart.some(item => (product.idProduct === item.idProduct))){
			removeFromCart(item);
		}
		// if(state.cart.includes(item)){
		// 	removeFromCart(item);
		// }
		else {
			addToCart(item);
		}
	}

	
	const pfzero = product.features[0].price;

	return (
		<div className="ProductItem">
			<div className="ProductItem__image-container" onClick={() => navigate(`/product/${product.idProduct}`)}>
				{product.features.some(feature => feature.discount !== 0) &&
					<div className="ProductItem__discount">- {(product.features.find(feature => feature.discount !== 0).discount * 100).toFixed().toString()}%</div>
				}
				{/* {product.images.length > 0 ?
					<img src={product.images[0]} alt={product.name}/> :
					<video 
					// controls
					// key={`${product.name}-video-${product.videos.indexOf(vd)}`}
					onClick={() => navigate(`/product/${product.idProduct}`)}
					>
					  <source src={product.videos[0]} type="video/mp4" />
					  <source src={product.videos[0]} type="video/webm" />
					</video>			
				} */}
				{product.videos.length > 0 ?
					<video autoPlay muted loop playsInline
					// controls
					// key={`${product.name}-video-${product.videos.indexOf(vd)}`}
					onClick={() => navigate(`/product/${product.idProduct}`)}
					>
					  <source src={product.videos[0]} type="video/mp4" />
					  <source src={product.videos[0]} type="video/webm" />
					</video>			 :
					<img src={product.images[0]} alt={product.name}/>
				}
			</div>
			<div className="product-info">
				<div>
					<div>
					{
						product.catalogue.idCategory !== 'F001' ?
						<p >
							{
						((product.catalogue.idCategory !== 'F001') && (product.features.some(feature => feature.discount !==0))) ?
						<span>${`${(pfzero - pfzero * product.features[0].discount.toFixed(2)).toFixed().toString().slice(-6, -3)}.${(pfzero - pfzero * product.features[0].discount.toFixed(2)).toFixed().toString().slice(-3)}`}</span> : <span></span>
					}
					<span className={discountPrice}>
							${product.features[0].price.toString().length > 6 && (`${product.features[0].price.toString().slice(- product.features[0].price.toString().length, -6)}.`)}
							{product.features[0].price.toString().slice(-6, -3)}
							.
							{product.features[0].price.toString().slice(-3)}
					</span>
						</p> : <p></p>
					}
					</div>
					{/* <p>${product.features[0].price}</p> */}
					<p onClick={() => navigate(`/product/${product.idProduct}`)}>{product.name}</p>
				</div>
				<figure onClick={() => navigate(`/product/${product.idProduct}`)}>
				{/* <figure onClick={() => {handleClick(product)}}> */}
					{/* {
						state.cart.includes(product) && <My_bt_add_to_cart fillDark='var(--very-light-pink)' stroke='var(--very-light-pink)'/>
					}{
						!state.cart.includes(product) && <My_bt_add_to_cart fillDark='var(--hospital-green)' stroke='var(--hospital-green)'/>
					} */}
					{
						state.cart.some(item => (product.idProduct === item.idProduct)) && <My_bt_add_to_cart fillDark='var(--very-light-pink)' stroke='var(--very-light-pink)'/>
					}{
						!state.cart.some(item => (product.idProduct === item.idProduct)) && <My_bt_add_to_cart fillDark='var(--hospital-green)' stroke='var(--hospital-green)'/>
					}
				</figure>
			</div>
		</div>
	);
}

export { ProductItem };