import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MyTextInput, MyCheckbox, MySelect, MyRadio } from "./MyInputs";
import { Payment } from "./Payment";
import { ScrollToTop } from "../hooks/useScrollToTop";
import { postProducts } from "../hooks/postProducts";

const stepTwoAPI = 'https://miapiatmosphere.cloud/api/v100/shopping-car/step-two';

let states = {
    Amazonas: ["Leticia", "Puerto Nariño"],
    Antioquia: [
        'Medellín',
        'Abejorral',
        'Abriaquí',
        'Alejandría',
        'Amagá',
        'Amalfi',
        'Andes',
        'Angelópolis',
        'Angostura',
        'Anorí',
        'Anza',
        'Apartadó',
        'Arboletes',
        'Argelia',
        'Armenia',
        'Barbosa',
        'Bello',
        'Betania',
        'Betulia',
        'Briceño',
        'Buriticá',
        'Cáceres',
        'Caicedo',
        'Caldas',
        'Campamento',
        'Cañasgordas',
        'Caracolí',
        'Caramanta',
        'Carepa',
        'Carolina',
        'Caucasia',
        'Chigorodó',
        'Cisneros',
        'Ciudad Bolívar',
        'Cocorná',
        'Concepción',
        'Concordia',
        'Copacabana',
        'Dabeiba',
        'Don Matías',
        'Ebéjico',
        'El Bagre',
        'El Carmen de Viboral',
        'El Santuario',
        'Entrerrios',
        'Envigado',
        'Fredonia',
        'Frontino',
        'Giraldo',
        'Girardota',
        'Gómez Plata',
        'Granada',
        'Guadalupe',
        'Guarne',
        'Guatapé',
        'Heliconia',
        'Hispania',
        'Itagui',
        'Ituango',
        'Belmira',
        'Jardín',
        'Jericó',
        'La Ceja',
        'La Estrella',
        'La Pintada',
        'La Unión',
        'Liborina',
        'Maceo',
        'Marinilla',
        'Montebello',
        'Murindó',
        'Mutatá',
        'Nariño',
        'Necoclí',
        'Nechí',
        'Olaya',
        'Peñol',
        'Peque',
        'Pueblorrico',
        'Puerto Berrío',
        'Puerto Nare',
        'Puerto Triunfo',
        'Remedios',
        'Retiro',
        'Rionegro',
        'Sabanalarga',
        'Sabaneta',
        'Salgar',
        'San Andrés de Cuerquía',
        'San Carlos',
        'San Francisco',
        'San Jerónimo',
        'San José de La Montaña',
        'San Juan de Urabá',
        'San Luis',
        'San Pedro de los Milagros',
        'San Pedro de Uraba',
        'San Rafael',
        'San Roque',
        'San Vicente',
        'Santa Bárbara',
        'Santafé de Antioquia',
        'Santa Rosa de Osos',
        'Santo Domingo',
        'Segovia',
        'Sonsón',
        'Sopetrán',
        'Támesis',
        'Tarazá',
        'Tarso',
        'Titiribí',
        'Toledo',
        'Turbo',
        'Uramita',
        'Urrao',
        'Valdivia',
        'Valparaíso',
        'Vegachí',
        'Venecia',
        'Vigía del Fuerte',
        'Yalí',
        'Yarumal',
        'Yolombó',
        'Yondó',
        'Zaragoza',
    ],
    Arauca: [
        'Arauca',
        'Arauquita',
        'Cravo Norte',
        'Fortul',
        'Puerto Rondón',
        'Saravena',
        'Tame',
    ],
    Atlántico: [
        'Barranquilla',
        'Baranoa',
        'Campo de La Cruz',
        'Candelaria',
        'Galapa',
        'Juan de Acosta',
        'Luruaco',
        'Malambo',
        'Manatí',
        'Palmar de Varela',
        'Piojó',
        'Polonuevo',
        'Ponedera',
        'Puerto Colombia',
        'Repelón',
        'Sabanagrande',
        'Sabanalarga',
        'Santa Lucía',
        'Santo Tomás',
        'Soledad',
        'Suan',
        'Tubará',
        'Usiacurí',
    ],
    'Bogotá DC': ['Bogotá, D.C'],
    Bolívar: [
        'Cartagena',
        'Achí',
        'Altos del Rosario',
        'Arenal',
        'Arjona',
        'Arroyohondo',
        'Barranco de Loba',
        'Calamar',
        'Cantagallo',
        'Cicuco',
        'Córdoba',
        'Clemencia',
        'El Carmen de Bolívar',
        'El Peñón',
        'El Guamo',
        'Hatillo de Loba',
        'Magangué',
        'Mahates',
        'Margarita',
        'María la Baja',
        'Montecristo',
        'Mompós',
        'Morales',
        'Norosí',
        'Pinillos',
        'Regidor',
        'Río Viejo', 
        'San Cristóbal',
        'San Estanislao', 
        'San Fernando', 
        'San Jacinto',
        'San Jacinto del Cauca',
        'San Juan Nepomuceno', 
        'San Martín de Loba', 
        'San Pablo de Borbur',
        'Santa Catalina', 
        'Santa Rosa', 
        'Santa Rosa del Sur',
        'Simití',
        'Soplaviento',
        'Talaigua Nuevo', 
        'Tiquisio',
        'Turbaco',
        'Turbaná',
        'Villanueva',
        'Zambrano',
    ],
    Boyacá: [
        'Tunja',
        'Almeida',
        'Aquitania',
        'Arcabuco',
        'Belén',
        'Berbeo',
        'Betéitiva',
        'Boavita',
        'Boyacá',
        'Briceño',
        'Buena Vista',
        'Busbanzá',
        'Caldas',
        'Campohermoso',
        'Cerinza',
        'Ciénega',
        'Chinavita',
        'Chiquinquirá',
        'Chiscas',
        'Chita',
        'Chitaraque',
        'Chivatá',
        'Cómbita',
        'Coper',
        'Corrales',
        'Covarachía',
        'Cubará',
        'Cucaita',
        'Cuítiva',
        'Chíquiza',
        'Chivor',
        'Duitama',
        'El Cocuy',
        'El Espino',
        'Firavitoba',
        'Floresta',
        'Gachantivá',
        'Gameza',
        'Garagoa',
        'Guacamayas',
        'Guateque',
        'Guayatá',
        'Güicán',
        'Iza',
        'Jenesano',
        'Jericó',
        'Labranzagrande',
        'La Capilla',
        'La Uvita',
        'La Victoria',
        'Macanal',
        'Maripí',
        'Miraflores',
        'Mongua',
        'Monguí',
        'Moniquirá',
        'Motavita',
        'Muzo',
        'Nobsa',
        'Nuevo Colón',
        'Oicatá',
        'Otanche',
        'Pachavita',
        'Páez',
        'Paipa',
        'Pajarito',
        'Panqueba',
        'Pauna',
        'Paya',
        'Paz de Río',
        'Pesca',
        'Pisba',
        'Puerto Boyacá',
        'Quípama',
        'Ramiriquí',
        'Ráquira',
        'Rondón',
        'Saboyá',
        'Sáchica',
        'Samacá',
        'San Eduardo',
        'San José de Pare',
        'San Luis de Gaceno',
        'San Mateo',
        'San Miguel de Sema',
        'San Pablo de Borbur',
        'Santana',
        'Santa María',
        'Santa Rosa de Viterbo',
        'Santa Sofía',
        'Sativanorte',
        'Sativasur',
        'Siachoque',
        'Soatá',
        'Socotá',
        'Socha',
        'Sogamoso',
        'Somondoco',
        'Sora',
        'Sotaquirá',
        'Soracá',
        'Susacón',
        'Sutamarchán',
        'Sutatenza',
        'Tasco',
        'Tenza',
        'Tibaná',
        'Tibasosa',
        'Tinjacá',
        'Tipacoque',
        'Toca',
        'Togüí',
        'Tópaga',
        'Tota',
        'Turmequé',
        'Tununguá',
        'Tuta',
        'Tutazá',
        'Umbita',
        'Ventaquemada',
        'Villa de Leyva',
        'Viracachá',
        'Zetaquira',
    ],
    Caldas: [
        'Manizales',
        'Aguadas',
        'Anserma',
        'Aranzazu',
        'Belalcázar',
        'Chinchiná',
        'Filadelfia',
        'La Dorada',
        'La Merced',
        'Manzanares',
        'Marmato',
        'Marquetalia',
        'Marulanda',
        'Neira',
        'Norcasia',
        'Pácora',
        'Palestina',
        'Pensilvania',
        'Riosucio',
        'Risaralda',
        'Salamina',
        'Samaná',
        'San José',
        'Supía',
        'Victoria',
        'Villamaría',
        'Viterbo',
    ],
    Caquetá: [
        'Florencia',
        'Albania',
        'Belén de Los Andaquies',
        'Cartagena del Chairá',
        'Curillo',
        'El Doncello',
        'El Paujil',
        'La Montañita',
        'Milán',
        'Morelia',
        'Puerto Rico',
        'San José del Fragua',
        'San Vicente del Caguán',
        'Solano',
        'Solita',
        'Valparaíso',
    ],
    Casanare: [
        'Yopal',
        'Aguazul',
        'Chámeza',
        'Hato Corozal',
        'La Salina',
        'Maní',
        'Monterrey',
        'Nunchía',
        'Orocué',
        'Paz de Ariporo',
        'Pore',
        'Recetor',
        'Sabanalarga',
        'Sácama',
        'San Luis de Gaceno',
        'Támara',
        'Tauramena',
        'Trinidad',
        'Villanueva',
    ],
    Cauca: [
        'Popayán',
        'Almaguer',
        'Argelia',
        'Balboa',
        'Bolívar',
        'Buenos Aires',
        'Cajibío',
        'Caldono',
        'Caloto',
        'Corinto',
        'El Tambo',
        'Florencia',
        'Guachené',
        'Guapi',
        'Inzá',
        'Jambaló',
        'La Sierra',
        'La Vega',
        'López',
        'Mercaderes',
        'Miranda',
        'Morales',
        'Padilla',
        'Páez',
        'Patía',
        'Piamonte',
        'Piendamó',
        'Puerto Tejada',
        'Puracé',
        'Rosas',
        'San Sebastián',
        'Santa Rosa',
        'Santander de Quilichao',
        'Silvia',
        'Sotara',
        'Suárez',
        'Sucre',
        'Timbío',
        'Timbiquí',
        'Toribio',
        'Totoró',
        'Villa Rica',
    ],
    Cesar: [
        'Valledupar',
        'Aguachica',
        'Agustín Codazzi',
        'Astrea',
        'Becerril',
        'Bosconia',
        'Chimichagua',
        'Chiriguaná',
        'Curumaní',
        'El Copey',
        'El Paso',
        'Gamarra',
        'González',
        'La Jagua de Ibirico',
        'La Gloria',
        'Manaure',
        'Pailitas',
        'Pelaya',
        'Pueblo Bello',
        'La Paz',
        'Río de Oro',
        'San Alberto',
        'San Diego',
        'San Martín',
        'Tamalameque',
    ],
    Chocó: [
        'Quibdó',
        'Acandí',
        'Alto Baudó',
        'Atrato',
        'Bagadó',
        'Bahía Solano',
        'Bajo Baudó',
        'Belén de Bajira',
        'Bojaya',
        'Carmen del Darien',
        'Cértegui',
        'Condoto',
        'El Cantón del San Pablo',
        'El Carmen de Atrato',
        'El Litoral del San Juan',
        'Istmina',
        'Juradó',
        'Lloró',
        'Medio Atrato',
        'Medio Baudó',
        'Medio San Juan',
        'Nóvita',
        'Nuquí',
        'Río Iro',
        'Río Quito',
        'Riosucio',
        'San José del Palmar',
        'Sipí',
        'Tadó',
        'Unguía',
        'Unión Panamericana',
    ],
    Córdoba: [
        'Montería',
        'Ayapel',
        'Buenavista',
        'Canalete',
        'Cereté',
        'Chimá',
        'Chinú',
        'Ciénaga de Oro',
        'Cotorra',
        'La Apartada',
        'Lorica',
        'Los Córdobas',
        'Momil',
        'Montelíbano',
        'Moñitos',
        'Planeta Rica',
        'Pueblo Nuevo',
        'Puerto Escondido',
        'Puerto Libertador',
        'Purísima',
        'Sahagún',
        'San Andrés Sotavento',
        'San Antero',
        'San Bernardo del Viento',
        'San Carlos',
        'San José de Uré',
        'San Pelayo',
        'Tierralta',
        'Tuchín',
        'Valencia',
    ],
    Cundinamarca: [
        'Agua de Dios',
        'Albán',
        'Anapoima',
        'Anolaima',
        'Apulo',
        'Arbeláez',
        'Beltrán',
        'Bituima',
        'Bojacá',
        'Cabrera',
        'Cachipay',
        'Cajicá',
        'Caparrapí',
        'Cáqueza',
        'Carmen de Carupa',
        'Chaguaní',
        'Chía',
        'Chipaque',
        'Choachí',
        'Chocontá',
        'Cogua',
        'Cota',
        'Cucunubá',
        'El Colegio',
        'El Peñón',
        'El Rosal',
        'Facatativá',
        'Fomeque',
        'Fosca',
        'Funza',
        'Fúquene',
        'Fusagasugá',
        'Gachala',
        'Gachancipá',
        'Gachetá',
        'Gama',
        'Girardot',
        'Granada',
        'Guachetá',
        'Guaduas',
        'Guasca',
        'Guataquí',
        'Guatavita',
        'Guayabal de Siquima',
        'Guayabetal',
        'Gutiérrez',
        'Jerusalén',
        'Junín',
        'La Calera',
        'La Mesa',
        'La Palma',
        'La Peña',
        'La Vega',
        'Lenguazaque',
        'Macheta',
        'Madrid',
        'Manta',
        'Medina',
        'Mosquera',
        'Nariño',
        'Nemocón',
        'Nilo',
        'Nimaima',
        'Nocaima',
        'Venecia',
        'Pacho',
        'Paime',
        'Pandi',
        'Paratebueno',
        'Pasca',
        'Puerto Salgar',
        'Pulí',
        'Quebradanegra',
        'Quetame',
        'Quipile',
        'Ricaurte',
        'San Antonio del Tequendama',
        'San Bernardo',
        'San Cayetano',
        'San Francisco',
        'San Juan de Río Seco',
        'Sasaima',
        'Sesquilé',
        'Sibaté',
        'Silvania',
        'Simijaca',
        'Soacha',
        'Sopó',
        'Subachoque',
        'Suesca',
        'Supatá',
        'Susa',
        'Sutatausa',
        'Tabio',
        'Tausa',
        'Tena',
        'Tenjo',
        'Tibacuy',
        'Tibirita',
        'Tocaima',
        'Tocancipá',
        'Topaipí',
        'Ubalá',
        'Ubaque',
        'Une',
        'Útica',
        'Vergara',
        'Vianí',
        'Villa de San Diego de Ubate',
        'Villagómez',
        'Villapinzón',
        'Villeta',
        'Viotá',
        'Yacopí',
        'Zipacón',
        'Zipaquirá',
    ],
    Guainía: [
        'Inírida',
        'Barranco Minas',
    ],
    Guaviare: [
        'San José del Guaviare',
        'Calamar', 
        'El Retorno',
        'Miraflores',
    ],
    Huila: [
        'Neiva',
        'Acevedo',
        'Agrado',
        'Aipe',
        'Algeciras',
        'Altamira',
        'Baraya',
        'Campoalegre',
        'Colombia',
        'Elías',
        'Garzón',
        'Gigante',
        'Guadalupe',
        'Hobo',
        'Iquira',
        'Isnos',
        'La Argentina',
        'La Plata',
        'Nátaga',
        'Oporapa',
        'Paicol',
        'Palermo',
        'Palestina',
        'Pital',
        'Pitalito',
        'Rivera',
        'Saladoblanco',
        'San Agustín',
        'Santa María',
        'Suaza',
        'Tarqui',
        'Tesalia',
        'Tello',
        'Teruel',
        'Timaná',
        'Villavieja',
        'Yaguará',
    ],
    'La Guajira': [
        'Riohacha',
        'Albania',
        'Barrancas',
        'Dibula',
        'Distracción',
        'El Molino',
        'Fonseca',
        'Hatonuevo',
        'La Jagua del Pilar',
        'Maicao',
        'Manaure',
        'San Juan del Cesar',
        'Uribia',
        'Urumita',
        'Villanueva',
    ],
    Magdalena: [
        'Santa Marta',
        'Algarrobo',
        'Aracataca',
        'Ariguaní',
        'Cerro San Antonio',
        'Chivolo',
        'Ciénaga',
        'Concordia',
        'El Banco',
        'El Piñon',
        'El Retén',
        'Fundación',
        'Guamal',
        'Nueva Granada',
        'Pedraza',
        'Pijiño del Carmen',
        'Pivijay',
        'Plato',
        'Pueblo Viejo',
        'Remolino',
        'Sabanas de San Angel',
        'Salamina',
        'San Sebastián de Buenavista',
        'San Zenón',
        'Santa Ana',
        'Santa Bárbara de Pinto',
        'Sitionuevo',
        'Tenerife',
        'Zapayán',
        'Zona Bananera',
    ],
    Meta: [
        'Villavicencio',
        'Acacias',
        'Barranca de Upía',
        'Cabuyaro',
        'Castilla la Nueva',
        'Cubarral',
        'Cumaral',
        'El Calvario',
        'El Castillo',
        'El Dorado',
        'Fuente de Oro',
        'Granada',
        'Guamal',
        'Mapiripán',
        'Mesetas',
        'La Macarena',
        'Lejanías',
        'Puerto Concordia',
        'Puerto Gaitán',
        'Puerto López',
        'Puerto Lleras',
        'Puerto Rico',
        'Restrepo',
        'San Carlos de Guaroa',
        'San Juan de Arama',
        'San Juanito',
        'San Martín',
        'Uribe',
        'Vista Hermosa',
    ],
    Nariño: [
        'Pasto',
        'Albán',
        'Aldana',
        'Ancuyá',
        'Arboleda',
        'Barbacoas',
        'Belén',
        'Buesaco',
        'Chachagüí',
        'Colón',
        'Consaca',
        'Contadero',
        'Córdoba',
        'Cuaspud',
        'Cumbal',
        'Cumbitara',
        'El Charco',
        'El Peñol',
        'El Rosario',
        'El Tablón de Gómez',
        'El Tambo',
        'Funes',
        'Guachucal',
        'Guaitarilla',
        'Gualmatán',
        'Iles',
        'Imués',
        'Ipiales',
        'La Cruz',
        'La Florida',
        'La Llanada',
        'La Tola',
        'La Unión',
        'Leiva',
        'Linares',
        'Los Andes',
        'Magüí',
        'Mallama',
        'Mosquera',
        'Nariño',
        'Olaya Herrera',
        'Ospina',
        'Francisco Pizarro',
        'Policarpa',
        'Potosí',
        'Providencia',
        'Puerres',
        'Pupiales',
        'Ricaurte',
        'Roberto Payán',
        'Samaniego',
        'Sandoná',
        'San Andrés de Tumaco',
        'San Bernardo',
        'San Lorenzo',
        'San Pablo',
        'San Pedro de Cartago',
        'Santa Bárbara',
        'Santacruz',
        'Sapuyes',
        'Taminango',
        'Tangua',
        'Túquerres',
        'Yacuanquer',
    ],
    'Norte de Santander': [
        'Cúcuta',	
        'Ábrego',	
        'Arboledas',	
        'Bochalema',	
        'Bucarasica',	
        'Cáchira',	
        'Cácota',	
        'Chinácota',	
        'Chitagá',	
        'Convención',	
        'Cucutilla',	
        'Durania',	
        'El Carmen', 	
        'El Tarra', 	
        'El Zulia', 	
        'Gramalote',	
        'Hacarí',	
        'Herrán',	
        'La Esperanza', 	
        'La Playa de Belén', 	
        'Labateca',	
        'Los Patios', 	
        'Lourdes Centro',	
        'Mutiscua',	
        'Ocaña',	
        'Pamplona',	
        'Pamplonita',	
        'Puerto Santander', 	
        'Ragonvalia',	
        'Salazar de Las Palmas	', 
        'San Calixto Occidente', 
        'San Cayetano', 	
        'Santiago',	
        'Santo Domingo de Silos', 	
        'Sardinata',	
        'Teorama',	
        'Tibú',	
        'Toledo',	
        'Villa Caro', 	
        'Villa del Rosario', 	        
    ],
    Putumayo: [
        'Mocoa',
        'Colón',
        'Orito',
        'Puerto Asís',
        'Puerto Caicedo',
        'Puerto Guzmán',
        'Leguízamo',
        'Sibundoy',
        'San Francisco',
        'San Miguel',
        'Santiago',
        'Valle de Guamez',
        'Villagarzón',
    ],
    Quindío: [
        'Armenia',
        'Buenavista',
        'Calarcá',
        'Circasia',
        'Córdoba',
        'Filandia',
        'Génova',
        'La Tebaida',
        'Montenegro',
        'Pijao',
        'Quimbaya',
        'Salento',
    ],
    Risaralda: [
        'Pereira',
        'Apía',
        'Balboa',
        'Belén de Umbría',
        'Dosquebradas',
        'Guática',
        'La Celia',
        'La Virginia',
        'Marsella',
        'Mistrató',
        'Pueblo Rico',
        'Quinchía',
        'Santa Rosa de Cabal',
        'Santuario',
    ],
    'San Andrés y Providencia': [
        'San Andrés',
        'Providencia',
    ],
    Santander: [
        'Bucaramanga',
        'Aguada',
        'Albania',
        'Aratoca',
        'Barbosa',
        'Barichara',
        'Barrancabermeja',
        'Betulia',
        'Bolívar',
        'Cabrera',
        'California',
        'Carcasí',
        'Cepitá',
        'Capitanejo',
        'Cerrito',
        'Charalá',
        'Charta',
        'Chimá',
        'Chipatá',
        'Cimitarra',
        'Concepción',
        'Confines',
        'Contratación',
        'Coromoro',
        'Curití',
        'El Carmen de Chucurí',
        'El Guacamayo',
        'El Peñón',
        'El Playón',
        'Encino',
        'Enciso',
        'Florián',
        'Floridablanca',
        'Galán',
        'Gambita',
        'Girón',
        'Guaca',
        'Guadalupe',
        'Guapotá',
        'Guavatá',
        'Güepsa',
        'Hato',
        'Jesús María',
        'Jordán',
        'La Belleza',
        'Landázuri',
        'La Paz',
        'Lebríja',
        'Los Santos',
        'Macaravita',
        'Málaga',
        'Matanza',
        'Mogotes',
        'Molagavita',
        'Ocamonte',
        'Oiba',
        'Onzaga',
        'Palmar',
        'Palmas del Socorro',
        'Páramo',
        'Piedecuesta',
        'Pinchote',
        'Puente Nacional',
        'Puerto Parra',
        'Puerto Wilches',
        'Rionegro',
        'Sabana de Torres',
        'San Andrés',
        'San Benito',
        'San Gil',
        'San Joaquín',
        'San José de Miranda',
        'San Miguel',
        'San Vicente de Chucurí',
        'Santa Bárbara',
        'Santa Helena del Opón',
        'Simacota',
        'Socorro',
        'Suaita',
        'Sucre',
        'Suratá',
        'Tona',
        'Valle de San José',
        'Vélez',
        'Vetas',
        'Villanueva',
        'Zapatoca',
    ],
    Sucre: [
        'Sincelejo',
        'Buenavista',
        'Caimito',
        'Coloso',
        'Corozal',
        'Coveñas',
        'Chalán',
        'El Roble',
        'Galeras',
        'Guaranda',
        'La Unión',
        'Los Palmitos',
        'Majagual',
        'Morroa',
        'Ovejas',
        'Palmito',
        'San Benito Abad',
        'San Juan de Betulia',
        'San Luis de Sincé',
        'San Marcos',
        'San Onofre',
        'San Pedro',
        'Santiago de Tolú',
        'Sampués',
        'Sucre',
        'Tolú Viejo',
    ],
    Tolima: [
        'Ibagué',
        'Alpujarra',
        'Alvarado',
        'Ambalema',
        'Anzoátegui',
        'Armero',
        'Ataco',
        'Cajamarca',
        'Carmen de Apicala',
        'Casabianca',
        'Chaparral',
        'Coello',
        'Coyaima',
        'Cunday',
        'Dolores',
        'Espinal',
        'Falan',
        'Flandes',
        'Fresno',
        'Guamo',
        'Herveo',
        'Honda',
        'Icononzo',
        'Lérida',
        'Líbano',
        'Mariquita',
        'Melgar',
        'Murillo',
        'Natagaima',
        'Ortega',
        'Palocabildo',
        'Piedras',
        'Planadas',
        'Prado',
        'Purificación',
        'Rio Blanco',
        'Roncesvalles',
        'Rovira',
        'Saldaña',
        'San Antonio',
        'San Luis',
        'Santa Isabel',
        'Suárez',
        'Valle de San Juan',
        'Venadillo',
        'Villahermosa',
        'Villarrica',
    ],
    'Valle del Cauca': [
        'Cali',
        'Alcalá',
        'Andalucía',
        'Ansermanuevo',
        'Argelia',
        'Bolívar',
        'Buenaventura',
        'Buga',
        'Bugalagrande',
        'Caicedonia',
        'Calima - El Darién', 
        'Candelaria',
        'Cartago',
        'Dagua',
        'El Águila', 
        'El Cairo', 
        'El Cerrito', 
        'El Dovio', 
        'Florida',
        'Ginebra',
        'Guacarí',
        'Jamundí',
        'La Cumbre', 
        'La Unión', 
        'La Victoria', 
        'Obando',
        'Palmira',
        'Pradera',
        'Restrepo',
        'Riofrío',
        'Roldanillo',
        'San Pedro', 
        'Sevilla',
        'Toro',
        'Trujillo',
        'Tuluá',
        'Ulloa',
        'Versalles',
        'Vijes',
        'Yotoco',
        'Yumbo',
        'Zarzal',        
    ],
    Vaupés: [
        'Mitú',
        'Carurú',
        'Taraira',
    ],
    Vichada: [
        'Puerto Carreño',
        'La Primavera',
        'Santa Rosalía',
        'Cumaribo',
    ], 
};

function Delivery(){
    const [delivery ,setDelivery] = React.useState(true);
    const [selectedState, setSelectedState] = React.useState('');
    const [paymentData, setPaymentData] = React.useState({});
    ScrollToTop();
    const [layer, setLayer] = React.useState('Layer-on');
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [chargingIcon, setChargingIcon] = React.useState('NotCharging');
    const location = useLocation();
    const navigate = useNavigate();
    console.log('location.state traida a delivery: ', location.state);
    const manyTowns = selectedState ? states[`${selectedState}`] : ['No hay municipios'];
    let pastValues = JSON.parse(JSON.stringify(location.state.values));
    const idTransaction = location.state.idTransaction;
    const totalPrice = location.state.totalPrice; 

    async function handleEpaycoCall(totalInfo){
        const respuestaBack = await postProducts(stepTwoAPI, totalInfo);
		console.log('la respuesta que regresa el Back es: ', respuestaBack.data);
        console.log('totalINFO es:::', totalInfo);

            if(respuestaBack.status === 200 && respuestaBack.data.state){
                let stateData = JSON.parse(JSON.stringify(respuestaBack.data));
                console.log('stateData que regresa el back es: ', stateData);
                setPaymentData(JSON.parse(JSON.stringify(stateData)));
                // navigate("/checkout/email", {state:{...stateData}});
                // if(Object.keys(paymentData).length > 0){
                //     setTimeout(() => {
                //         document.getElementById("epayco-link-button").click()
                //     }, 3000);
                //     }
            }
            else {
                alert('Ha ocurrido un error, vuelve al inicio del carrito.');
                navigate("/checkout/cart"); 
            }
            // document.getElementById("epayco-link-button").click()
    }

    useEffect(() => {
        console.log('selectedState: ', selectedState);
        if(selectedState){
        setLayer('Layer-off');
        } else {
        setLayer('Layer-on');
        }
    }, [selectedState]);

    return (
        <>
            <section className="email-container">
                <h3>Identificación</h3>
                <div>
                    <p>{`${location.state.values.firstName} ${location.state.values.lastName}`}
                    <br />{location.state.values.email}
                    <br />{location.state.values.phone}</p>
                </div>
                {/* <div>
                    <p>Nombre Apellidos
                    <br />correo@gmail.com
                    <br />322 521 1235</p>
                </div> */}
            </section>
            <section className="email-container">
                <h3>Envio</h3>
                <div>                    
                    <div className="buttons-container">
                        <button type="button" delivery={`${delivery}`} onClick={() =>setDelivery(true)}>Entrega en tu casa</button>
                        <button type="button" delivery={`${!delivery}`} onClick={() =>setDelivery(false)}>Recoge en tienda</button>
                    </div>
                    {delivery &&
                    <Formik
                        initialValues={{
                            department: '',
                            town: '',
                            address: '',
                            additionalInfo: '',
                            neighbourhood: '',
                            recipient: '', 
                            deliveryOption: 'Pago a contraentrega',
                        }}
                        validationSchema={Yup.object({
                            department: Yup.string()
                                .oneOf(
                                  Object.keys(states),
                                  'Departamento invalido'
                                )
                                .required('Requerido'),
                            // town: Yup.string()
                            //     .oneOf(
                            //        manyTowns,
                            //       'Ciudad invalida'
                            //     )
                            //     .required('Requerido'),
                            town: Yup.string()
                                .required('Requerido'),
                            address: Yup.string()
                                .required('Requerido'),
                            recipient: Yup.string()
                                .required('Requerido'),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                          setIsDisabled(true);
                          setChargingIcon('Charging');
                          setTimeout(() => {
                            // alert(JSON.stringify(values, null, 2));
                            console.log('lo que envio al back: ', {...values, ...pastValues, idTransaction: idTransaction, totalPrice: totalPrice});
                            handleEpaycoCall({...values, ...pastValues, idTransaction: idTransaction, totalPrice: totalPrice})
                            // document.getElementById("epayco-link-button").click();
                            setSubmitting(false);
                          }, 400);
                        }}
                    >
                    <Form>
                        <p className="first-request">Ingresa los datos para el envio de tu pedido</p>
                        <MySelect label="Departamento:" name="department"                      
                            onClick={ e => {
                                setSelectedState(e.target.value);
                              }}
                        >
                          <option value="">Seleccionar Departamento</option>
                          {Object.keys(states).map(e => 
                                <option value={`${e}`} key={`${e}`}>{`${e}`}</option>
                            )}
                        </MySelect>
                        
                        {/* <div>
                            <div className={`City-layer ${layer}`}></div>
                        <MySelect label="Ciudad o municipio:" name="town">
                          <option value="">Seleccionar ciudad</option>
                          {selectedState &&
                           states[`${selectedState}`].map(e => 
                            <option value={`${e}`} key={`${e}`}>{`${e}`}</option>)
                          }
                        </MySelect>
                        </div> */}

                        <MyTextInput 
                            label="Ciudad o municipio:" 
                            name="town"
                            type="text"
                            placeholder="Escribe tu ciudad o municipio"
                          />
                        
                        <MyTextInput
                            label="Direccion:"
                            name="address"
                            type="text"
                            placeholder="Direccion"
                        />
                        <MyTextInput
                            label="Informacion adicional (ej: apto. 402)"
                            name="additionalInfo"
                            type="text"
                            placeholder="Direccion complementaria"
                        />
                        <MyTextInput
                            label="Barrio / localidad"
                            name="neighbourhood"
                            type="text"
                            placeholder="Opcional"
                        />
                        <MyTextInput
                            label="Destinatario:"
                            name="recipient"
                            type="text"
                            placeholder="recibe..."
                        />
                        <p>Metodo de envío:</p>
                        <br></br>
                        <MyCheckbox name="deliveryOption" checked>
                            <div className="delivery-prices">
                                <span>Pago del envío contra entrega.</span>
                            </div>                          
                        </MyCheckbox>
                        {Object.keys(paymentData).length > 0 &&
                            (<Payment paymentData={paymentData}></Payment>) 
                           }
                        <button
                            disabled={isDisabled} className="primary-button" type="submit" >Ir al pago
                        </button>
                    </Form>
                    </Formik>
                    }
                    {!delivery &&
                    <Formik  
                        initialValues={{
                            store: '',
                        }}
                        validationSchema={Yup.object({
                          store: Yup.string()
                              .required('Debes elegir una tienda'),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            setIsDisabled(true);
                            setChargingIcon('Charging');
                            setTimeout(() => {
                                console.log('lo que envio al back: ', {...values, ...pastValues, idTransaction: idTransaction, totalPrice: totalPrice});
                                handleEpaycoCall({...values, ...pastValues, idTransaction: idTransaction, totalPrice: totalPrice});
                            //   alert(JSON.stringify(values, null, 2));
                              setSubmitting(false);
                            }, 400);
                        }}                              
                    >
                    <Form>
                        <p className="first-request">Elige en donde recoger tu pedido</p>
                        <p>Tienda:</p>
                        <MyRadio name="store" value="Ibague, Calle 115 sur # 49 - 96, San Francisco de Aparco" className='checkbox-extended'>
                          <div>
                              <p>Ibagué, Tolima
                                  <br />Calle 115 Sur # 49 - 96
                                  <br />San Francisco de Aparco
                                  <br />+57 3117000782
                              </p>
                          </div>
                        </MyRadio>
                        {/* <MyRadio name="store" value="Villavicencio">
                          <div>
                              <p>Villavicencio, Meta
                                  <br />Centro comercial 
                                  <br />local 
                                  <br />31..........
                              </p>
                          </div>
                        </MyRadio>   */}
                        <p>Opcion de recogida:</p>
                        <br />
                        <MyCheckbox name="deliveryOption" checked>
                            <div className="delivery-prices">
                                <span>Listo en 5 dias hábiles.</span><span>¡Gratis!</span>
                            </div>                          
                        </MyCheckbox>
                        {Object.keys(paymentData).length > 0 && (
                            <Payment paymentData={paymentData}></Payment>
                        )}
                        <button
                            disabled={isDisabled} className="primary-button" type="submit">
                          Ir al pago
                        </button>
                    </Form>
                    </Formik>
                    }
                </div>
                <div class={`lds-roller ${chargingIcon}`} ><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        </>
    )
}

export { Delivery };