import React from "react";
import { useGetProducts } from "./useGetProducts";

// const API = 'https://api.escuelajs.co/api/v1/products';
// const API = "https://miapiatmosphere.cloud/api/v100/products/get-products";
//const API = 'http://localhost:8080/api/v100/products/get-products';

function useProducts(API) {
  const products = useGetProducts(API);

  const [searchValue, setSearchValue] = React.useState("");
  let searchedProducts = [];
  if (!searchedProducts >= 1) {
    searchedProducts = products;
  } else {
    searchedProducts = products.filter((product) => {
      const productText = product.name.toLowerCase();
      const searchText = searchValue.toLowerCase();
      return productText.includes(searchText);
    });
  }

  const states = {
    searchValue,
    searchedProducts,
  };
  const stateUpdates = {
    setSearchValue,
  };
  return { states, stateUpdates };
}

export { useProducts };
