import React from "react";
import whatsappIcon from '../assets/icons/whatsapp.svg';
import { Link } from "react-router-dom";
import '../styles/Whatsapp.scss';

const Whatsapp = () => {
    const WhatsAppNumber = 3117000782;

    return(
        <a href={`https://wa.me/${WhatsAppNumber}`}>
            <div className="whatsapp-container">
                <img src={whatsappIcon} alt="WhatsApp Contact button"></img>
            </div>
        </a>
    );
}

export { Whatsapp };