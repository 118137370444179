import React from "react";
import { ProductItem } from "../components/ProductItem";
import '../styles/productList.scss';
import { useGetProducts } from "../hooks/useGetProducts";

const ProductList = ({searchedProducts}) => {

    return(
		<section className="main-container">
			<div className="ProductList">
				{searchedProducts.map(product => (
					<ProductItem product={product} key={product.idProduct} />
				))}
			</div>
		</section>
    );
}

export { ProductList };