import React from "react";
import { useField } from "formik";

const MyTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    return (
      <div className="input-container">
        <label htmlFor={props.id || props.name}>{label}</label>
        <input className="text-input" {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error text-input__error">{meta.error}</div>
        ) : null}
      </div>
    );
  };

  const MyCheckbox = ({ children, ...props }) => {
    // React treats radios and checkbox inputs differently other input types, select, and textarea.
    // Formik does this too! When you specify `type` to useField(), it will
    // return the correct bag of props for you -- a `checked` prop will be included
    // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
    const [field, meta] = useField({ ...props, type: 'checkbox' });
    return (
      <div className="checkbox-container">
        <label className="checkbox-input">
          <input type="checkbox" {...field} {...props} />
          <span className="checkmark"></span>
          {children}
        </label>
        {meta.touched && meta.error ? (
          <div className="error checkbox-input__error">{meta.error}</div>
        ) : null}
      </div>
    );
  };


  const MySelect = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <div className="input-container">
        <label htmlFor={props.id || props.name}>{label}</label>
        <br></br>
        <select {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    );
  };

  const MyRadio = ({ children, ...props }) => {
    // React treats radios and checkbox inputs differently other input types, select, and textarea.
    // Formik does this too! When you specify `type` to useField(), it will
    // return the correct bag of props for you -- a `checked` prop will be included
    // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
    const [field, meta] = useField({ ...props, type: 'radio' });
    return (
      <div className="checkbox-container">
        <label className="checkbox-input">
          <input type="radio" {...field} {...props} />
          <span className="checkmark"></span>
          {children}
        </label>
        {meta.touched && meta.error ? (
          <div className="error checkbox-input__error">{meta.error}</div>
        ) : null}
      </div>
    );
  };



  export { MyTextInput, MyCheckbox, MySelect, MyRadio };