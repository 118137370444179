import React, { useEffect } from "react";
import paymentButton from "../assets/icons/payment_button.svg";

function Payment({paymentData}){
    const {idTransaction,  publicKey, state, totalPrice} = paymentData;
    console.log('paymentData recibida es: ', paymentData);
    var scriptTag = document.createElement("script");
    // scriptTag.async = true;
    scriptTag.type = "text/javascript";
    // scriptTag.className="primary-button";
    scriptTag.className="epayco-button primary-button";
    
    scriptTag.src = "https://checkout.epayco.co/checkout.js";
    scriptTag.setAttribute("data-epayco-key", publicKey);
    scriptTag.setAttribute("data-epayco-amount", totalPrice);
    scriptTag.setAttribute("data-epayco-tax", totalPrice*0.19);
    scriptTag.setAttribute("data-epayco-tax-base", totalPrice-totalPrice*0.19);
    scriptTag.setAttribute("data-epayco-name", `Compra No. ${idTransaction}`);
    scriptTag.setAttribute("data-epayco-description", `Compra No. ${idTransaction}`);
    scriptTag.setAttribute("data-epayco-currency", "cop");
    scriptTag.setAttribute("data-epayco-country", "co");
    scriptTag.setAttribute("data-epayco-test", "false");
    scriptTag.setAttribute("data-epayco-external", "false");
    // scriptTag.setAttribute("data-epayco-response", "http://localhost:3000/#/checkout/response");
    scriptTag.setAttribute("data-epayco-acepted", "https://ilovemystyle.com.co/#/checkout/payment-accepted");
    scriptTag.setAttribute("data-epayco-rejected", "https://ilovemystyle.com.co/#/checkout/payment-rejected");
    scriptTag.setAttribute("data-epayco-pending", "https://ilovemystyle.com.co/#/checkout/payment-pending");
    scriptTag.setAttribute("data-epayco-confirmation", "https://miapiatmosphere.cloud/epayco/v100/confirmation");
    scriptTag.setAttribute("data-epayco-methodconfirmation", "post");
    scriptTag.setAttribute("data-epayco-invoice", idTransaction);
    scriptTag.setAttribute("data-epayco-button", paymentButton);
    // ( document.getElementsByTagName("body")[0] || document.documentElement ).appendChild( scriptTag );

    scriptTag.onload = () => {
        console.log('Script loaded successfuly');
        document.getElementsByClassName('epayco-button-render')[0].setAttribute("id",`epayco-link-button`);
        document.getElementById("epayco-link-button").click();
      };

    scriptTag.onerror = () => {
      console.log('Error occurred while loading script');
    };

    useEffect(() => {
        // ( document.getElementsByTagName("body")[0] || document.documentElement ).appendChild( scriptTag );
        ( document.getElementById("epayco-form") || document.documentElement ).appendChild( scriptTag );
    },[]);

    
    return(
        <div className="paybutton-container" id="epayco-form"></div>
    )
}

export { Payment };