import { useEffect, useState } from "react";
import axios from "axios";

const useGetProducts = (API) => {

    const [products, setProducts] = useState([]);
    

    useEffect(()=>{
        async function axiosData() {
        const response = await axios(API);
        setProducts(response.data);
        }
        axiosData();
    },[]);    


    return products;
};

export { useGetProducts };