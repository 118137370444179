import {  useLocation, useNavigate } from "react-router";
import { useGetProducts } from "../hooks/useGetProducts";
import { ProductItem } from "../components/ProductItem";
import { CategoryList } from "./CategoryList";
import { useEffect, useState } from "react";
import axios from "axios";

const endpoint = `https://miapiatmosphere.cloud/api/v100/products/get-products?catalogue=`;

const CatalogList = () => {    
    let { state } = useLocation();
    const navigate = useNavigate();
    let location = useLocation();
    const [newState, setNewState] = useState({});
    const [loading, setLoading] = useState(true);

    const categories = useGetProducts('https://miapiatmosphere.cloud/api/v100/products/get-categories');

    // console.warn("STATE",state);
    // console.log('this is the location: ', location);

    useEffect(() => {
        if(!state){
            async function CallingIds(){
                let categories = await axios(`https://miapiatmosphere.cloud/api/v100/products/get-categories`);
                console.log('categories y newState', categories.data, newState);
                let itemFound = categories.data.find(e => e.name.toLowerCase() === location.pathname.split('/')[2]);
                let idFound = itemFound.idCategory;
                console.log('idFound', idFound);
                let cataloguesFound = await axios(`https://miapiatmosphere.cloud/api/v100/products/get-catalogues/${idFound}`);
                console.log('cataloguesFound: ', cataloguesFound);
                let specificCatalog = cataloguesFound.data.find(e => e.description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, '-') === location.pathname.split('/')[3]);
                console.log('pathname3', location.pathname.split('/')[3]);
                console.log('specificCatalog: ', specificCatalog);
                setNewState(JSON.parse(JSON.stringify(
                    {
                        category: idFound,
                        description: specificCatalog.description,
                        id: specificCatalog.idCatalogue,
                    }
                )));
                setLoading(false);
            };
            CallingIds()
            console.log('newState: ', newState);
        }
    },[]);

    return(
    <>
            {state &&
            <>
                <div className="route-container route-container__catalogues-level">
                <span className="dynamic-span" onClick={() => navigate(`/catalog/${categories.find(e => e.idCategory === state.category).name.toLowerCase()}`)}>{categories.length > 0 ? (categories.find(e => e.idCategory === state.category).name) : '.....'}</span>
                <span> &gt; </span><span>{state.description}</span>
                </div>
                <CategoryList APIList={`${endpoint}${state.id}`}></CategoryList>
            </>
            }
            {(!state && loading) && <p>Charging .....</p>}
            {(!state && !loading) && 
            <>
            <div className="route-container route-container__catalogues-level">
                        <span className="dynamic-span" onClick={() => navigate(`/catalog/${location.pathname.split('/')[2]}`)}>{location.pathname.split('/')[2].charAt().toUpperCase()}{location.pathname.split('/')[2].slice(1)}</span>
                        <span> &gt; </span><span>{newState.description}</span>
                        </div>
                        <CategoryList APIList={`${endpoint}${newState.id}`}></CategoryList>
                            </>
            }
    </>);
}

export {CatalogList}