import React, { useEffect } from "react";
import { Header } from "../components/Header";
import { Whatsapp } from "../components/Whatsapp";
import { useLocation } from "react-router-dom";

const Layout = ({searchValue, setSearchValue}) => {

    const location = useLocation();
    useEffect(() => setSearchValue(''), [location]);

    return(
        <div className="Layout">
            {/* <Header searchValue={searchValue} setSearchValue={setSearchValue}/> */}
            <Whatsapp />
        </div>
    );
};

export { Layout };