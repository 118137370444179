import React, { useEffect, useState, useLayoutEffect } from "react";
import { Home } from "../pages/Home";
import { Searchbar } from "../components/Searchbar";
import { useParams, useLocation } from "react-router";
import { useProducts } from "../hooks/useProducts";
import { useGetCalatog } from "../hooks/useGetCatalog";
import "../styles/Catalog.scss";
import scrollRight from "../assets/icons/scrollRight.svg";
import scrollLeft from "../assets/icons/scrollLeft.svg";
import { Link, useNavigate } from 'react-router-dom';
import { useGetProducts } from "../hooks/useGetProducts";
import axios from "axios";
import { CategoryList } from "../components/CategoryList";


const Catalog = () => {

    const { slug } = useParams();
    // const [displayLeft, setDisplayLeft] = useState("notDisplay");
    const [displayLeft, setDisplayLeft] = useState("display");
    const [displayRight, setDisplayRight] = useState("display");
    const [size, setSize] = useState([0, 0]);
  // const GeneralAPI = "https://miapiatmosphere.cloud/api/v100/products/get-products?category=M001";

    // let { state } = useLocation();
    const [ loading, setLoading ]  = useState(true);
    const [idCategory, setIdCategory] = useState('');
    const [catalog, setCatalog] = useState([]);
    const [GeneralAPI, setGeneralAPI] = useState('')

    const categories = useGetProducts('https://miapiatmosphere.cloud/api/v100/products/get-categories');

    async function axiosData() {
      if(idCategory !== ''){
        const response = await axios(`https://miapiatmosphere.cloud/api/v100/products/get-catalogues/${idCategory}`);
        setCatalog(response.data);
        setLoading(false);
      } else {
        console.log('no se ha traido la respuesta');
      }
      }


    const categoryFound = categories.find(e => e.name.toLowerCase() === slug);

    useEffect(() => {
    if(categoryFound){
      setIdCategory(categoryFound.idCategory);
      setGeneralAPI(`https://miapiatmosphere.cloud/api/v100/products/get-products?category=${idCategory}`);
      console.log('idCategory: ', categoryFound.idCategory);
      console.log('categoriesList: ', catalog);
    } else {
      console.log('cargando idCategory....');
    }
    })

    useEffect(() => {
      axiosData();
      // setDisplayRight("display"); // esto esta mientras funciona la funcion que detecta el scroll automaticamente
      // setDisplayLeft("notDisplay"); // esto esta mientras funciona la funcion que detecta el scroll automaticamente
    }, [idCategory]);
    useEffect(() => {console.log('GeneralAPI', GeneralAPI)}, [idCategory]);
  

    // const {
    //     states, stateUpdates
    //   } = useProducts(GeneralAPI);
  

    //   const {
    //     searchValue ,
    //     searchedProducts ,
    //   } = states;

    	  
		//   const {
    //     setSearchValue ,
    //     } = stateUpdates;
      
    
    var container = document.getElementById('Product-catalogs__container');

    const detectingScroll = (element) => {
      console.log('element: ', element);
        if(element === null){
          console.log('No ha cargado el contenedor');
        }
        else{
          
      console.log('element.scrollWidth: ', element.scrollWidth);
      console.log('element.clientWidth: ', element.clientWidth);
          if(element.scrollWidth === element.clientWidth){
            setDisplayLeft("notDisplay");
            setDisplayRight("notDisplay");
          } 
          else {
            console.log('There is scroll.');
            sideScroll(container,'left',25,100,10);
          }
        }
    }


    function sideScroll(element,direction,speed,distance,step){
      let scrollAmount = 0;
      var slideTimer = setInterval(function(){
        // if(container.scrollLeft > 0){
        //   setDisplayLeft("display");
        // }
        // if(container.scrollLeft === 0){
        //   setDisplayLeft("notDisplay");
        // }
        // if(container.scrollLeft + container.clientWidth >= 0.99*container.scrollWidth){
        //   setDisplayRight("notDisplay");
        // }
        // if(container.scrollLeft + container.clientWidth < 0.99*container.scrollWidth){
        //   setDisplayRight("display");
        // }
          if(direction === 'left'){
              element.scrollLeft -= step;
          } else {
              element.scrollLeft += step;
          }
          scrollAmount += step;
          if(scrollAmount >= distance){
              window.clearInterval(slideTimer);
          }
      }, speed);
  }


  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

    // useEffect(() => detectingScroll(container),[size]);

    return(
    <>
    {/* <Searchbar 
        className="navbar-search_mobile"  
          searchValue={searchValue}
          setSearchValue={setSearchValue}
    /> */}
    <div className='Catalogs__container'>
      <span onClick={() => {sideScroll(container,'left',25,100,10);}}>
        <img src={scrollLeft}  className={displayLeft} id="catalog-scroll-left" alt="scroll hacia la izquierda"></img>
      </span>
      <div className='Product-catalogs__container' id="Product-catalogs__container">
        <ul className='Product-catalogs'>
         {loading ? (<p>Cargando...</p>) : (catalog.map(c => {
          // console.log('c', c);
          return(
					<li key={c.idCatalogue}>
            <Link to={c.description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, '-')} state={{id: c.idCatalogue, description: c.description, category: idCategory}}>{c.description}</Link></li>
				)
        }))}  
        </ul>
      </div>
      <span onClick={() => {
        sideScroll(container,'right',25,100,10);
      }}>
        <img src={scrollRight} className={displayRight} id="catalog-scroll-right" alt="scroll hacia la derecha"></img>
      </span>
    </div>
    {GeneralAPI === `https://miapiatmosphere.cloud/api/v100/products/get-products?category=${idCategory}` ? (<CategoryList APIList={GeneralAPI}/>) : (<p>Cargando productos de la categoria</p>) }
    </>)
}

export { Catalog };