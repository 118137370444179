import React from "react";
import '../styles/mobileNavbar.scss';
import { Link } from 'react-router-dom';
import { useGetProducts } from "../hooks/useGetProducts";

const MobileNavbar = ({setToggleMobile}) => {

  const dealToggles = () =>{
    setTimeout(()=> setToggleMobile(false), 2000);
  }

  const seasonPoint = 'https://miapiatmosphere.cloud/api/v100/products/get-covers';
  const covers = useGetProducts(seasonPoint);

    return(
        <div className="navbar-mobile">
            <ul onClick={dealToggles}>
              <li>
                <Link to="/catalog/familia" state={{id: "F001"}}>FAMILIA</Link>
              </li>
              <li>
                <Link to="/catalog/mujeres">MUJERES</Link>
              </li>
              <li>
                <Link to="/catalog/hombres">HOMBRES</Link>
              </li>
              <li>
                <Link to="/catalog/infantil">INFANTIL</Link>
              </li>
              <li>
                <Link to="/catalog/accesorios">ACCESORIOS</Link>
              </li>
              <li>
                <Link to="/catalog/calzado"  state={{id: "C001"}}>CALZADO</Link>
              </li>
              <li>
                <Link to="/promotional-products" className="Promotional-li">OFERTAS</Link>
              </li>
              <li>
                {covers[1] ? <Link to={`/season/${covers[1].season.toLowerCase()}`}>{covers[1].message.toUpperCase()}</Link>
                : 'ULTIMA COLECCIÓN'}
              </li>
              <li>
                <Link to="/">INICIO</Link>
              </li>
            </ul>
        </div>
    )
}

export { MobileNavbar };