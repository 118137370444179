import React from "react";
import { useGetProducts } from "../hooks/useGetProducts";
import { Footer } from "./Footer";
import '../styles/CatalogColors.scss';
import flechita from '../assets/icons/flechita.svg';

const CatalogColors = () => {
    const patterns = useGetProducts('https://miapiatmosphere.cloud/api/v100/products/get-prints');

    return(
        <>
        <section className="patterns-container">
        <div className="Patterns__back-bottom" onClick={() => {
            window.close();
        }}>
            <img src={flechita} alt="arrow" className="arrow-back"/>
            <span>Volver</span></div>
        <h2>Colección de estampados y diseños</h2>
            {patterns.length > 0 ? <div>{patterns.map(pt => (
                <img src={pt} key={pt}></img>
            ))
                }</div>  : <p>cargando...</p>}
        </section>
        <Footer />
        </>
    );
}

export { CatalogColors}