import React from "react";
import { ProductList } from "../containers/ProductList";


const Home = ({searchedProducts}) => {
    return(
        <>
            <ProductList searchedProducts={searchedProducts}/>            
        </>
    );
};

export { Home };