import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/AppContext';
import '../styles/OrderItem.scss';
import close from '../assets/icons/icon_close.png';

const OrderItem = ({product}) => {
	const { removeFromCart, increaseQuantity, decreaseQuantity } = useContext(AppContext);

	const handleRemove = (item) => {
		removeFromCart(item);
	}

	const foundFeature = product.features.find(e => e.idFeature === product.idFeature);

	return (
	<div className='OrderItem--container'>
		<div className="OrderItem">
			<figure>
				<img src={product.images[0]} alt={product.name} />
			</figure>
			<div>
				<p className="OrderedItem--productTitle">{product.name}</p>
				<div className="OrderedItem__quantityContainer">
					<button className='subtract-button' onClick={()=> {decreaseQuantity(product)}}>-</button>
					<span className='OrderedItem--quantity'>{product.quantity}</span>
					<button className='sum-button' onClick={()=> {increaseQuantity(product)}}>+</button></div>
			</div>
			<div className='OrderItem__priceContainer'>
			<img 
				src={close} 
				alt="close"
				className="OrderedItem__closeIcon"
				onClick={() => {handleRemove(product)}} />
			<p>
			{/* {
				product.features.find(e => e.idFeature === product.idFeature).discount !== 0 ? (
					<>
						<span  className='Discount-price'>${product.features.find(e => e.idFeature === product.idFeature).price}</span>
						<br/>${product.features.find(e => e.idFeature === product.idFeature).price - product.features.find(e => e.idFeature === product.idFeature).price * product.features.find(e => e.idFeature === product.idFeature).discount}
					</>
				) : (<span>${product.features.find(e => e.idFeature === product.idFeature).price}</span>)
			} */}
			{
				foundFeature.discount !== 0 ? (
					<>
						<span  className='Discount-price'>${`${(foundFeature.price.toString().slice(-6, -3))}.${(foundFeature.price.toString().slice(- 3))}`}</span>
						<br/>${`${(foundFeature.price - foundFeature.price * foundFeature.discount.toFixed(2)).toFixed().toString().slice(-6, -3)}.${(foundFeature.price - foundFeature.price * foundFeature.discount.toFixed(2)).toFixed().toString().slice(-3)}`}
					</>
				) : (<span>${`${(foundFeature.price).toString().slice(-6, -3)}.${(foundFeature.price).toString().slice( -3)}`}</span>)
			}
			<br/>Unidad
			</p>
			<p>$
			{(((foundFeature.price - (foundFeature.price * foundFeature.discount.toFixed(2))).toFixed() * product.quantity).toString().length > 6) && (`${((foundFeature.price - (foundFeature.price * foundFeature.discount.toFixed(2))) * product.quantity).toString().slice(- ((foundFeature.price - (foundFeature.price * foundFeature.discount.toFixed(2))).toFixed() * product.quantity).toString().length, -6)}.`)}
				{`${((foundFeature.price - (foundFeature.price * foundFeature.discount.toFixed(2))).toFixed() * product.quantity).toString().slice(-6, -3)}.${((foundFeature.price - (foundFeature.price * foundFeature.discount.toFixed(2))).toFixed() * product.quantity).toString().slice(-3)}`}
			</p>
			</div>
		</div>
		<div>
		<ul className='Product-features'>
			{product.selectedAttribute && (
			<li>{product.selectedAttribute}</li>)}
			{product.selectedSize && (<li>{product.selectedSize}</li>)}
			{product.selectedColor && (<li>{product.selectedColor}</li>)}
		</ul>
		</div>
	</div>
	);
}

export { OrderItem };