import axios from "axios";
import { useNavigate } from "react-router-dom";


async function postProducts (API, data) {
    console.log('esta es la data que se envia al back: ', data);
    let respuesta = {};
    await axios.post(API, data)
      .then((response) => {
        console.log('response: ', response);
        respuesta = JSON.parse(JSON.stringify(response));
        // console.log('esta es la respuesta que tomamos: ', respuesta);
      }, (error) => {
        console.log(error);
      });
    console.log('esta es la respuesta que tomamos: ', respuesta);
    return respuesta; 
}

export { postProducts };