import React, { useEffect, useState} from "react";
import facebookLogo from '../assets/logos/facebook.svg';
import instagramLogo from "../assets/logos/instagram.svg";
import '../styles/Footer.scss';
import epaycoSeguroLogo from "../assets/icons/logo-epayco-seguro.png";
import { useLocation } from "react-router-dom";


const Footer = () => {

    const [landingMode, setLandingMode] = useState(false);
    
    const pathname = useLocation().pathname.split('/');
    
    useEffect(() =>{
        if(pathname.some(e => e === 'landingpage')){
            setLandingMode(true);
        }});

    return(
        <div className="Footer-container">
            <div>
                <ul>
                    <li>
                        <a href="/#/terminos-y-condiciones" target='_blank'>Términos y Condiciones</a>
                    </li>
                    <li><a href="/#/data-policy" target='_blank'>Tratamiento de datos.</a></li>
                    <li>
                        <a href="https://www.instagram.com/ilovemystyle_/" target='_blank'>
                        <img className="socialNetwork" src={instagramLogo} alt="intagram" />
                        </a>
                        <a href="https://www.facebook.com/ilovemystylecol/" target='_blank'>
                        <img className="socialNetwork" src={facebookLogo} alt="facebook" />
                        </a>
                    </li>
                </ul>
            </div>
            <div className="copyRights-container">                
                <span>©2023 I Love My Style.</span>
                <span>Todos los derechos reservados.</span>
                <p>Calle 115 # 49 - 96 San Francisco de Aparco,<br />Ibague-Tolima</p>
            </div>
            {!landingMode && <div className="epaycosafe-container">
                <div>Pago seguro</div>
                <img className="epaycoSafe" src={epaycoSeguroLogo} alt="Pago seguro" />
            </div>}
        </div>
    )
}

export { Footer };