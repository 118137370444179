import React from "react"
import '../styles/searchbar.scss';

const Searchbar = ({className, searchValue, setSearchValue}) => {

    const onSearchValueChange = (event) => {
        console.log(event.target.value)
        setSearchValue(event.target.value)
    }

    return(
        <div className="Searchbar-container">
        <input 
            type="text" 
            placeholder='¿Qué producto buscas?' 
            value={searchValue}
            className={`navbar-search ${className}`}
            onChange={onSearchValueChange}
        />
        </div>
    );
}

export { Searchbar };