import React, { useContext, useEffect } from "react";
import '../styles/Payment-status.scss';
import mediosDePago from "../assets/logos/medios_de_pago_epayco.png"; 
import { AppContext } from "../context/AppContext";

function PaymentAccepted() { 
    const { state, clearCart } = useContext(AppContext);

    useEffect(()=> {
        localStorage.removeItem('MyItems_V1');
        clearCart()}, []);

    return(
        <>
        <section className="email-container">
            <h3 >Pedido confirmado</h3>
            <div>
                <span className="payment-title payment-accepted">¡Felicidades!</span>
                <p>Tu pago se ha realizado con exito, en menos de 24 horas nos comunicaremos contigo para coordinar el envío de tu pedido.</p>
            </div>
        </section>
        {/* <div className="payment-methods-container">
        <span>Medios de pago</span>
        <img src={mediosDePago} alt="medios-de-pago" className="medios-de-pago"></img>
        </div> */}
        </>
    );
}


function LandingDeliveryConfirmed() { 
    const { state, clearCart } = useContext(AppContext);

    useEffect(()=> {
        localStorage.removeItem('MyItems_V1');
        clearCart()}, []);

    return(
        <>
        <section className="email-container">
            <h3 >Pedido confirmado</h3>
            <div>
                <span className="payment-title payment-accepted">¡Felicidades!</span>
                <p>Tu pedido se ha realizado con éxito, de 3 a 7 días hábiles estará en la puerta de tu hogar.</p>
            </div>
        </section>
        {/* <div className="payment-methods-container">
        <span>Medios de pago</span>
        <img src={mediosDePago} alt="medios-de-pago" className="medios-de-pago"></img>
        </div> */}
        </>
    );
}


function PaymentRejected() {    
    return(
        <>
            <section className="email-container">
            <h3 >Pago rechazado</h3>
            <div>
                <span className="payment-title payment-rejected">¡Lo sentimos!</span>
                <p>Tu transacción no se ha llevado a cabo, intenta nuevamente o vuelve al inicio.</p>
            </div>
        </section>
        {/* <div className="payment-methods-container">
        <span>Medios de pago</span>
        <img src={mediosDePago} alt="medios-de-pago" className="medios-de-pago"></img>
        </div> */}
        </>
    );
}

function PaymentPending() { 
    const { state, clearCart } = useContext(AppContext);
    
    useEffect(()=> {
        localStorage.removeItem('MyItems_V1');
        clearCart()}, []);

        
    return(
    <>
    <section className="email-container">
            <h3>Confirmación pendiente</h3>
            <div>
                <span className="payment-title payment-pending">¡Periodo de espera!</span>
                <p>La confirmación de tu pago se encuentra pendiente, una vez recibida, realizaremos el envio de tu pedido.</p>
            </div>
        </section>
        {/* <div className="payment-methods-container">
        <span>Medios de pago</span>
        <img src={mediosDePago} alt="medios-de-pago" className="medios-de-pago"></img>
        </div> */}
    </>
    );
}

export { PaymentAccepted, PaymentRejected, PaymentPending, LandingDeliveryConfirmed};