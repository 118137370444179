import React from "react";
import '../styles/Terms.scss';

function DataPolicy(){
    return(
        <>
            <section className="terms-container">
                <h3>Política de Tratamiento de Datos de la Tienda Virtual de Venta de Ropa y Accesorios I Love My Style</h3>
                <p>En I love my style ("Nosotros", "Nos" o "nuestro") nos comprometemos a proteger su privacidad y a cumplir con todas las leyes y regulaciones aplicables en materia de protección de datos. Esta Política de Tratamiento de Datos describe cómo recopilamos, utilizamos, divulgamos y protegemos la información personal que usted proporciona al utilizar nuestro sitio web [www.ilovemystyle.com.co] y al realizar compras en nuestra tienda virtual de venta de ropa y accesorios.</p>
                <ol>
                  <li>Información que Recopilamos
                    <ol>
                        <li>Recopilamos la siguiente información personal cuando usted utiliza nuestro sitio web y realiza compras:  
                            <ul>
                                <li>Nombre completo</li>
                                <li>Dirección de envío y facturación</li>
                                <li>Dirección de correo electrónico</li>
                                <li>Número de teléfono</li>
                                <li>Información de pago</li>
                                <li>Información demográfica</li>
                                <li>Información empresarial (opcional)</li>
                            </ul>                       
                        </li>   
                        <li>Además de la información personal mencionada anteriormente, también podemos recopilar información no personal, como datos de navegación, dirección IP, tipo de navegador, tiempo de acceso y páginas visitadas. Esta información se recopila de forma automática a través de cookies y tecnologías similares.</li>
                    </ol>
                  </li>
                  <li>Uso de la Información
                    <ol>
                      <li>Utilizamos la información personal recopilada para los siguientes fines:
                        <ul>
                          <li>Procesar y entregar sus pedidos.</li>
                          <li>Comunicarnos con usted sobre su compra, incluyendo confirmaciones y actualizaciones de envío.</li>
                          <li>Brindarle atención al cliente y soporte técnico.</li>
                          <li>Personalizar y mejorar su experiencia en nuestro sitio web.</li>
                          <li>Enviarle información sobre productos, promociones u ofertas especiales, siempre y cuando haya dado su consentimiento para recibir dichas comunicaciones.</li>
                          <li>Cumplir con nuestras obligaciones legales y resolver disputas.</li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li>Divulgación de la Información
                    <ol>
                      <li>No vendemos, alquilamos ni compartimos su información personal con terceros, excepto en las siguientes circunstancias:
                        <ul>
                          <li>Proveedores de servicios: Podemos compartir su información con terceros proveedores de servicios que nos ayudan a procesar y entregar sus pedidos, realizar pagos, enviar correos electrónicos y brindar servicios técnicos.</li>
                          <li>Cumplimiento legal: Podemos divulgar su información personal cuando sea necesario para cumplir con una obligación legal, una orden judicial o una solicitud de una autoridad gubernamental.</li>
                          <li>Consentimiento: Podemos compartir su información personal con terceros cuando usted nos haya dado su consentimiento explícito para hacerlo.</li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li>Seguridad de la Información
                    <ol>
                        <li>Implementamos medidas de seguridad técnicas y organizativas apropiadas para proteger su información personal contra el acceso no autorizado, la divulgación o la destrucción. Sin embargo, ninguna transmisión de datos a través de Internet ni ningún sistema de almacenamiento electrónico son completamente seguros. Por lo tanto, no podemos garantizar la seguridad absoluta de su información.</li>
                    </ol>
                  </li>
                  <li>Derechos del Titular de los Datos
                    <ol>
                        <li>Usted tiene ciertos derechos en relación con sus datos personales, que pueden incluir:
                          <ul>
                            <li>Acceso: Tiene derecho a acceder a la información personal que tenemos sobre usted.</li>
                            <li>Rectificación: Puede solicitar la corrección o actualización de su información personal si es inexacta o está incompleta.</li>
                            <li>Supresión: Puede solicitar la eliminación de su información personal, sujeto a restricciones legales o contractuales.</li>
                            <li>Oposición: Puede oponerse al procesamiento de su información personal en ciertas circunstancias.</li>
                          </ul>
                        </li>
                        <li>Para ejercer cualquiera de estos derechos, o si tiene alguna pregunta o inquietud sobre nuestra Política de Tratamiento de Datos, por favor contáctenos al telefono +57 3117000782 o al correo ventas@ilovemystyle.com.co.</li>
                    </ol>
                  </li>
                  <li>Cambios a la Política de Tratamiento de Datos
                    <ol>
                        <li>Nos reservamos el derecho de modificar esta Política de Tratamiento de Datos en cualquier momento. Cualquier cambio será efectivo a partir de la fecha de publicación en nuestro sitio web. Le recomendamos que revise periódicamente esta política para mantenerse informado sobre cualquier modificación.</li>
                    </ol>
                  </li>                  
                </ol>
                <p>
                Gracias por leer y aceptar nuestra Política de Tratamiento de Datos. Si tiene alguna pregunta o inquietud, por favor contáctenos al telefono +57 3117000782 o al correo electrónico ventas@ilovemystyle.com.co.
                </p>
            </section>
        </>
    );
}

export { DataPolicy };