import React from "react";
import '../styles/Terms.scss';

function Terms(){
    return(
        <>
            <section className="terms-container">
                <h3>Términos y Condiciones</h3>
                <p>Estos términos y condiciones establecen los derechos y obligaciones entre usted  ("Cliente", "Usuario" o "usted")  y I love my Style  ("Nosotros", "Nos" o "nuestro") al utilizar nuestro sitio web [www.ilovemystyle.com.co] y al realizar compras a través de nuestra tienda virtual de venta de ropa y accesorios. Al acceder y utilizar nuestro sitio web, usted acepta cumplir con estos Términos y Condiciones. Por favor, léalos detenidamente antes de realizar cualquier compra.</p>
                <ol>
                  <li>Información General
                    <ol>
                        <li>I love my style es una tienda virtual de venta de ropa y accesorios que ofrece productos y servicios en línea.</li>
                        <li>Usted declara que tiene al menos 18 años de edad y tiene capacidad legal para celebrar contratos vinculantes.</li>
                    </ol>
                  </li>
                  <li>Productos y Precios
                    <ol>
                      <li>Nos esforzamos por mostrar con precisión los colores, tallas y descripciones de los productos en nuestra tienda virtual. Sin embargo, no garantizamos que la visualización de su dispositivo reproduzca los colores o demas características de manera exacta.</li>
                      <li>Procuramos mostrar con claridad los detalles de cada producto. Sin embargo, en las imagenes de algunos productos pueden aparecer otros productos diferentes al deseado, o no corresponder completamente. Por lo anterior, para efectos del proceso de compra se toma en cuenta el nombre del producto y no las fotos del mismo.</li>
                      <li>Nos reservamos el derecho de modificar los precios de nuestros productos en cualquier momento sin previo aviso. Sin embargo, el precio aplicable a su compra será el indicado en el momento en que realice el pedido.</li>
                    </ol>
                  </li>
                  <li>Proceso de Compra
                    <ol>
                      <li>Al realizar una compra en nuestra tienda virtual, usted acepta proporcionar información precisa y completa sobre usted y su pedido. Nos reservamos el derecho de rechazar o cancelar cualquier pedido en cualquier momento por cualquier motivo.</li>
                      <li>Una vez que haya realizado un pedido, le enviaremos una confirmación por correo electrónico con los detalles de su compra. Esta confirmación no constituye una aceptación de su pedido, sino simplemente una confirmación de que lo hemos recibido.</li>
                      <li>Nos esforzamos por mantener actualizada la disponibilidad de nuestros productos. Sin embargo, en caso de que un producto que haya pedido no esté disponible, nos reservamos el derecho de cancelar su pedido o proporcionarle un producto alternativo de calidad y precio similares. En tal caso, le notificaremos y le ofreceremos opciones antes de proceder.</li>
                      <li>Tenga en cuenta que los productos que se encuentran en oferta o descuento no tienen derecho a cambio o devolución.</li>
                    </ol>
                  </li>
                  <li>Envío y Entrega
                    <ol>
                        <li>El envío de los productos se realizará a la dirección que usted haya proporcionado durante el proceso de compra o en su defecto a la tienda previamente elegida por usted de I love my style. Usted es responsable de asegurarse de que la dirección de envío sea precisa y completa.</li>
                        <li>Nos esforzamos por cumplir con los plazos de entrega estimados; sin embargo, no podemos garantizar la entrega en un plazo específico. No nos hacemos responsables de los retrasos causados por circunstancias fuera de nuestro control.</li>
                    </ol>
                  </li>
                  <li>Devoluciones y Reembolsos
                    <ol>
                        <li>Aceptamos devoluciones dentro de los 10 días posteriores a la recepción de los productos, siempre que los productos estén en su estado original, sin usar, sin lavar y con todas las etiquetas y embalajes originales.</li>
                        <li>Para iniciar el proceso de devolución, por favor contáctenos al telefono +57 3117000782 o al correo ventas@ilovemystyle.com.co. Los gastos de envío para la devolución correrán a cargo del Cliente, salvo en casos en los que el producto tenga defectos de fabricación o haya sido enviado incorrectamente.</li>
                        <li>Los reembolsos se realizarán dentro de los 15 días posteriores a la recepción de los productos devueltos y serán emitidos a través del mismo método de pago utilizado en la compra.</li>
                    </ol>
                  </li>
                  <li>Propiedad Intelectual
                    <ol>
                        <li>Todo el contenido presente en nuestra tienda virtual, incluyendo imágenes, videos, logotipos, texto y diseños, está protegido por derechos de propiedad intelectual y no puede ser utilizado sin nuestro permiso previo por escrito.</li>
                    </ol>
                  </li>
                  <li>Limitación de Responsabilidad
                    <ol>
                        <li>En la medida máxima permitida por la ley, no seremos responsables de ningún daño directo, indirecto, incidental, especial, consecuente o punitivo, incluyendo, entre otros, pérdida de beneficios, ingresos, datos o uso, incurridos por usted o cualquier tercero, ya sea en una acción contractual, negligencia u otra, relacionada con su uso de nuestro sitio web o la compra de productos en nuestra tienda virtual.</li>
                    </ol>
                  </li>
                  <li>Modificaciones a los Términos y Condiciones
                    <ol>
                        <li>Nos reservamos el derecho de modificar estos Términos y Condiciones en cualquier momento. Cualquier cambio será efectivo a partir de la fecha de publicación en nuestro sitio web. Le recomendamos que revise periódicamente estos Términos y Condiciones para mantenerse informado sobre cualquier modificación.</li>
                    </ol>
                  </li>
                  <li>Ley Aplicable y Jurisdicción
                    <ol>
                        <li> Estos Términos y Condiciones se regirán e interpretarán de acuerdo con las leyes de Colombia. Cualquier disputa que surja en relación con estos Términos y Condiciones estará sujeta a la jurisdicción exclusiva de los tribunales de este país.</li>
                    </ol>
                  </li>
                </ol>
                <p>
                Gracias por leer y aceptar nuestros Términos y Condiciones. Si tiene alguna pregunta o inquietud, por favor contáctenos al telefono +57 3117000782 o al correo electrónico ventas@ilovemystyle.com.co.
                </p>
            </section>
        </>
    );
}

export { Terms };