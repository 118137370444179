import React, { useState, useContext, useEffect} from "react";
import { AppContext } from "../context/AppContext";
import { OrderItem } from "../components/OrderItems";
import '../styles/Checkout.scss';
import flechita from '../assets/icons/flechita.svg';
import { Link, Outlet, useLocation } from "react-router-dom";
import mediosDePago from "../assets/logos/medios_de_pago_epayco.png"; 


const Checkout = () => {

	const { state } = useContext(AppContext);
	const [stateStep, setStateStep] = React.useState([true, false, false, false]);	
	const location = useLocation(); 
	
	console.log(location);

	const sumTotal = () => {
		const reducer = (accumulator, currentValue) => (accumulator + (currentValue.quantity*currentValue.price));
		const sum = state.cart.reduce(reducer, 0);
		return sum;
	}

	useEffect(()=>{
		switch(location.pathname){
			case "/checkout/cart":
				return setStateStep([true, false, false, false]);
			case "/checkout/email":
				return setStateStep([true, true, false, false]);
			case "/checkout/delivery-and-payment":
				return setStateStep([true, true, true, false]);
			default:
				return setStateStep([true, true, true, true]);
		}
	}, [location])

    return(
		<section className="checkout">
			<h3 className="checkout__cart-title">CARRITO DE COMPRAS</h3>
			<div className="steps-container">
				<div>
					<div className="step">
						<span colored={`${stateStep[0]}`}>1</span>
						<p>Resumen de la compra</p>
					</div>
				</div>
				<div className="step-space"></div>
				<div>
					<div className="step">
						<span colored={`${stateStep[1]}`}>2</span>
						<p>Ingresa tu email</p>
					</div>
				</div>
				<div className="step-space"></div>
				<div>
					<div className="step">
						<span colored={`${stateStep[2]}`}>3</span>
						<p>Envío y pago disponibles</p>
					</div>
				</div>
				<div className="step-space"></div>
				<div>
					<div className="step">
						<span colored={`${stateStep[3]}`}>4</span>
						<p>Pedido confirmado</p>
					</div>
				</div>
			</div>
			<Outlet />
			<div className="payment-methods-container">
        <span>Medios de pago</span>
        <img src={mediosDePago} alt="medios-de-pago" className="medios-de-pago"></img>
        </div>
			{/* <div className="title-container">
				<img src={flechita} className="my-order-arrowback" alt="arrow" onClick={()=> setToggleOrders(false)} />
				<p className="title">Mi orden</p>
			</div> */}

			{/* <div className="final-order-content">
				{state.cart.map(product => {
				     return(
					 <OrderItem product={product} key={`orderItem-${product.id}`} />
					 )
				})}
			</div>
			<div className="order">
					<p>
						<span>Total</span>
					</p>
					<p>${sumTotal()}</p>
				</div>
				<button className="primary-button">
					<Link to="/payment">Iniciar Pago</Link>
				</button> */}
		</section>);
}

export { Checkout };