import React, { useState, useContext, useEffect } from 'react';
import menu from '../assets/icons/icon_menu.svg';
// import logo from '../assets/logos/logo_yard_sale.svg';
// import devil from '../assets/logos/devil.svg';
import svgLogo from '../assets/logos/svg-logo.svg';
import logo from '../assets/logos/ILoveMyStyle-onlyLogo.gif';
import shoppingCart from '../assets/icons/icon_shopping_cart.svg';
import '../styles/Header.scss';
import { MobileNavbar } from './MobileNavbar';
import { Searchbar } from './Searchbar';
import { AppContext } from '../context/AppContext';
import { MyOrder } from '../containers/MyOrder';
import { Link, useNavigate } from 'react-router-dom';
import { useGetProducts } from '../hooks/useGetProducts';


const Header = ({searchValue, setSearchValue}) => {
    const { state } = useContext(AppContext);
    const [toggleMobile, setToggleMobile] = useState(false);
    const [toggleOrders, setToggleOrders] = useState(false);
    const navigate = useNavigate();

    const dealMenuToggle = () => {
      setTimeout(() =>
      setToggleMobile(false), 5000);
    }

    const seasonPoint = 'https://miapiatmosphere.cloud/api/v100/products/get-covers';
    const covers = useGetProducts(seasonPoint);


    return(
      <div className='Desktop-header-container'>
        <div className='Desktop-logo-container'>
          <img src={logo} alt="logo" className="nav-logo-desktop" onClick={()=> {navigate('/')}}/>
        </div>
        <nav className="navbar-desktop">
        <img 
            src={menu} 
            alt="menu" 
            className="menu"
            onClick={()=> {
                setToggleMobile(!toggleMobile);
                setToggleOrders(false);
                dealMenuToggle();}}
        />

        <div className="navbar-left">
          <img src={logo} alt="logo" className="nav-logo" onClick={()=> {navigate('/')}}/>

          <ul onClick={()=> setToggleOrders(false)}>
            <li>
              <Link to="/">INICIO</Link>
            </li>
            <li>
              <Link to="/catalog/familia" state={{id: "F001"}}>FAMILIA</Link>
            </li>
            <li>
              <Link to="/catalog/mujeres" state={{id: "M001"}}>MUJERES</Link>
            </li> 
            <li>
              <Link to="/catalog/hombres" state={{id: "H001"}}>HOMBRES</Link>
            </li>
            <li>
              <Link to="/catalog/infantil" state={{id: "I001"}}>INFANTIL</Link>
            </li>
            <li>
              <Link to="/catalog/accesorios"  state={{id: "A001"}}>ACCESORIOS</Link>
            </li>
            <li>
              <Link to="/catalog/calzado"  state={{id: "C001"}}>CALZADO</Link>
            </li>
            <li>
              <Link to="/promotional-products" className="Promotional-li">OFERTAS</Link>
            </li>
            <li>
              {covers[1] ? <Link to={`/season/${covers[1].season.toLowerCase()}`}>{covers[1].message.toUpperCase()}</Link>
              : 'ULTIMA COLECCIÓN'}
            </li>
          </ul>
        </div>

        <div className="navbar-right">
          <ul>
            <li>
              <Searchbar
                searchValue={searchValue}
                setSearchValue={setSearchValue} 
              />
            </li>
            <li 
              className="navbar-shopping-cart"
              onClick={()=>{
                setToggleOrders(!toggleOrders);
                setTimeout(() =>{setToggleOrders(false)}, 20000);
                setToggleMobile(false);
              }}
            >
              <img src={shoppingCart} alt="shopping cart" />
              {state.cart.length > 0 ? <div>{state.cart.length}</div> : <div>0</div>}
            </li>
          </ul>
        </div>
        {toggleMobile && <MobileNavbar setToggleMobile={setToggleMobile}/>}
        {/* <Searchbar 
          className="navbar-search_mobile"  
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        /> */}
        {toggleOrders && <MyOrder setToggleOrders={setToggleOrders}/>}
    </nav>
      </div>
    );
};

export { Header };